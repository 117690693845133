import  { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { toastNotify } from '../../components/Helper';
import { useForm } from 'react-hook-form';
import { fetchData }  from '../../components/Services/Api';

function ForgotPassword(){
    window.document.title = "Forgot Password | I2R Portal";
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();


    async function onSubmit(data) {
        setTinyloader(true);
        try {
            fetchData('forgot-password', data, 'post', (responce)=> {
                if(responce.success){
                    toastNotify('success', responce.message)
                    navigate('/login');
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', responce.message)
                    }
                }
                setTinyloader(false);
            }); 
        } catch (err) {
            toastNotify('danger', 'Oops! something went wrong, please try again later.');
            setTinyloader(false);
        }
    }

    return(
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="entry-box bg-white p-3 p-sm-4 p-sm-5 shadow-sm border-top border-warning border-3">
                            <div className="text-center">
                                <img src="images/logo.png" alt="Tawreed - Building Connections" className="w-100 maxw-180"/>
                            </div>
                            <hr className="text-muted opacity-10 mx-auto"/>
                            <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
                                <h4 className="text-center my-3">Forgot Password</h4>
                                <label className="input-group input-group-lg mb-3">
                                    <span className="input-group-text rounded-0">
                                        <svg className="icon tscale-1p3 opacity-50" role="img" aria-label="more">
                                            <use href="#icon_user" />
                                        </svg>
                                    </span>
                                    <input type="email" name="email" placeholder="Enter your email address" {...register('email', { required: true })} maxLength={32} className={errors.email && errors.email.type === "required" ? 'is-invalid form-control rounded-0' : 'form-control rounded-0' } />
                                </label>
                                <div className="d-flex justify-content-between">
                                    <div className="py-2">
                                        <button type="submit" className="btn btn-primary rounded-0" disabled={tinyloader}>
                                            {
                                                !tinyloader ? 'Submit'
                                                :
                                                <>
                                                <div className="spinner-border spinner-border-sm mr-1" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div> Submit 
                                                </>
                                            }
                                        </button>  
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="py-2 fw-medium d-block">
                                            Go to <Link to="/login" className="fw-medium" title="Login"> 
                                            <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                                <use href="#icon_lock" />
                                            </svg> Login</Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword