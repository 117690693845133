import { useContext, useState } from 'react'; 
import { Link, useNavigate } from "react-router-dom";
import { Context } from '../../components/Context';
import { useForm } from 'react-hook-form';
import { toastNotify } from '../../components/Helper';
import { postRequest }  from '../../components/Services/Api';

function Login(){

    window.document.title = "Login | I2R Portal";
    const navigate = useNavigate(); 
    const [tinyloader, setTinyloader] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    // eslint-disable-next-line no-unused-vars
    const [context, setContext] = useContext(Context);
    localStorage.removeItem('reload');

    async function onSubmit(data) {
        setTinyloader(true);
        try {
            postRequest('login', data, (response)=> {
                if(response.success){ 
                    localStorage.setItem('i2r-token',response.data.token);
                    localStorage.setItem('i2r-user',JSON.stringify(response.data.user)); 
                    setContext(response.data.user);
                    setTimeout(()=>{ 
                        navigate('/admin/dashboard');  
                    },500);
                }else{
                    toastNotify('danger', response.message);
                }
                setTinyloader(false);
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid Credentials.');
            setTinyloader(false);
        }
    }

    return(
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="entry-box bg-white p-3 p-sm-4 p-sm-5 shadow-sm border-top border-warning border-3">
                            <div className="text-center">
                                <img src="images/logo.png" alt="Portal - Building Connections" className="w-100 maxw-180" width="180px" height="84px" />
                            </div>
                            <hr className="text-muted opacity-10 mx-auto"/>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h4 className="text-center my-3">Login</h4>
                                   
                                <label className="input-group input-group-lg mb-3">
                                    <span className="input-group-text rounded-0">
                                        <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                            <use href="#icon_user" />
                                        </svg>
                                    </span>
                                    <input type="text" placeholder="Username" {...register('username', { required: true })} maxLength={32} className={`form-control rounded-0 ${errors.username && 'is-invalid'}` } />
                                </label>
                                <label className="input-group input-group-lg mb-3">
                                    <span className="input-group-text rounded-0">
                                        <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                            <use href="#icon_lock" />
                                        </svg>
                                    </span>
                                    <input type="password" placeholder="Password" {...register('password', { required: true })} maxLength={32} className={`form-control rounded-0 ${errors.password && 'is-invalid'}` } />
                                </label>
                                <button type="submit" className="btn btn-primary btn-lg w-100 rounded-0 mb-3" disabled={tinyloader}>
                                    { tinyloader && <span className="spinner-border spinner-border-sm mr-1"></span>} Login &#8594;
                                </button>
                            
                            </form>
                            <div className="d-flex justify-content-between">
                                <Link to="/forgot-password" className="py-2 fw-medium d-block" title="Forgot Password">
                                    <svg className="icon tscale-1p2 opacity-50" role="img" aria-label="more">
                                        <use href="#icon_lock" />
                                    </svg> Forgot Password?
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login