import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { scrollbarSetToTop, toastNotify, noAvatarHandle } from '../components/Helper';
import { getRequest }  from '../components/Services/Api'; 
import { Context } from '../components/Context';

function HeaderBar() {

    const assetURL = process.env.REACT_APP_ASSET_URL;
    const noImage  = `${assetURL}assets/images/avatar/user.jpg`;
    let [user, setUser] = useContext(Context);
    let [userId, setUserId] = useState(user.id ? user.id : 0);

    const navigate = useNavigate();

    const [userName, setUserName] = useState(user.name ? user.name : '' );
    const [ avatar, setAvatar ] = useState(user.profile_image ? `${assetURL}assets/images/avatar/${user.profile_image}` : noImage);

    useEffect(() => {

        if(user && user.id && Number(user.id)>0){
            getRequest(`users/getuser/${user.id}`, (response)=> {
                if(response.data.status === 200){  
                    setUser(previousState => ({
                        ...previousState,
                        user: response.data.user
                    }));
                    localStorage.setItem('i2r-user',JSON.stringify(response.data.user)); 
                    setUserId(response.data.user ? response.data.user.id : '');
                    setUserName(response.data.user ? response.data.user.name : '');
                }
            });
        }
        
        if(user && user.profile_image)
        {
            setAvatar(`${assetURL}assets/images/avatar/${user.profile_image}`);
            document.getElementById("header_profile_name").innerHTML = user.name;
            document.getElementById("profile_image").src = `${assetURL}assets/images/avatar/${user.profile_image}`;

        }else{
            document.getElementById("profile_image").src = noImage;
        }

        setTimeout(() => {  
            if(!user || !user.id || typeof user.id === "undefined" || user.id === 0){  
                navigate('/login')
            }
        }, 500);

        scrollbarSetToTop();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogout = () => {
        getRequest('logout', (res)=> {
            if(res.success){
                localStorage.clear();
                navigate('/login')
                toastNotify('success', res.message);
            }else{
                toastNotify('danger', 'Invalid action perform, please try again later');
            }
        }); 
    }

    const menuToggle = () => {
        document.body.classList.toggle('menu-active');
    }

    return (
        <>
            <nav className="admin-header" data-id={userId}>
                <div className="row m-0 h-100 align-items-center header-inner">
                    <div className="col-auto d-block d-xl-none">
                        <div className="admin-notification" role="button" onClick={menuToggle}>
                            <svg className="icon"><use href="#icon_menu"/></svg>
                        </div>
                    </div>
                    <div className="col-auto ms-auto">
                        <div className="dropdown d-inline-block align-middle">
                            <button type="button" className="admin-profile" data-bs-toggle="dropdown">
                                <img src={avatar}  onError={noAvatarHandle} alt="User" id='profile_image' width="36" height="36"/>
                                <div className="d-none d-lg-block mx-2 pe-1" id='header_profile_name'>
                                    {userName}
                                </div>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-0 p-2">
                                <li>
                                    <Link to="my-profile" title="My Profile" className="dropdown-item text-blue py-2">
                                        <svg className="icon tscale-1p2 opacity-75 me-2" role="img" aria-label="My Profile">
                                            <use href="#icon_user" />
                                        </svg>
                                        My Profile
                                    </Link>
                                </li>
                                <li>
                                    <Link to="password-reset" className="dropdown-item text-blue py-2" title="Change Password">
                                        <svg className="icon tscale-1p2 opacity-75 me-2" role="img" aria-label="Change Password">
                                            <use href="#icon_lock" />
                                        </svg>
                                        Change Password
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider text-white opacity-25" />
                                </li>
                                <li>
                                    <button type="button" onClick={() => { handleLogout(); } } className="dropdown-item text-blue py-2" title="Sign out">
                                        <svg className="icon tscale-1p2 opacity-75 me-2" role="img" aria-label="Sign out">
                                            <use href="#icon_logout" />
                                        </svg>
                                        Sign out
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="overlay-onmobile" onClick={menuToggle}></div>
        </>
    )
}
export default HeaderBar;