import  { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { Modal, Form, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { toastNotify, range} from '../../../components/Helper';
import DeleteConfirmation from "../../../components/DeleteConfirmation";
import TestReports from './TestReports';
import { getRequest, postFileData, deleteRequest }  from '../../../components/Services/Api';

function SiteInstallationDetails(props) {

    window.document.title = "Installation Details";
    
    const form = useForm();
    const { register, handleSubmit, setValue, formState: { errors } } = form;
    const [ reload, setReload ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [transformersModal, setTransformersModal] = useState(false);
    const [transformersChecklistModal, setTransformersChecklistModal] = useState(false);
    const [switchgearsChecklistModal, setSwitchgearsChecklistModal] = useState(false);
    const [pgCableChecklistModal, setPgCableChecklistModal] = useState(false);
    const [switchgearsModal, setSwitchgearsModal] = useState(false);
    const [batteryChargerModal, setBatteryChargerModal] = useState(false);
    const [details, setDetails] = useState([]);
    const [htSwitchgear, setHtSwitchgear] = useState({});
    const [ltSwitchgear, setLtSwitchgear] = useState({});
    const [id, setId] = useState(null);
    const [tabname, setTabname] = useState(null);
    const [panelType, setPanelType] = useState('HT');
    const [panelNo, setPanelNo] = useState(1);

    const intakeVoltage = (props.site && props.site.installation_type) ? props.site.installation_type : '';
    const blockShow = intakeVoltage === '400V' ? false : true;
    const site_id = props.siteId ? props.siteId : 0;
    const user = props.user ? props.user : null; 
    useEffect(() => {
        getRequest(`site/installation-details/${site_id}`, (response)=> {
            if(response.success){
                setDetails(response.data);
                setHtSwitchgear(response.data.switchgears.ht_switchgear);
                setLtSwitchgear(response.data.switchgears.lt_switchgear);
            }
        });
    },[site_id]);

    // Transformer Checklist Update 
    async function pgCableChecklistUpdate(pgcable) {
        setValue('site_id', site_id);
        setValue('type', 'pgcable');
        setValue('tabname', 'update_site_checklist');
        setValue('status', pgcable ? pgcable.status : 'Normal');
        setValue('remarks', pgcable ? pgcable.remarks : '');
        setPgCableChecklistModal(true);
    }
    // Battery Chargers Add
    async function pgBatteryChargerAdd(chargers) {
        setValue('tabname', 'add_battery_charger');
        setValue('site_id', site_id);
        setValue('charger_id', 0);
        setValue('float_voltage', '');
        setValue('float_current', '');
        setValue('boost_voltage', '');
        setValue('boots_current', '');
        setValue('electrolyte', '');
        setValue('remarks', '');

        setBatteryChargerModal(true);
    }
    // Battery Chargers Update
    async function pgBatteryChargerUpdate(data) {
        setValue('tabname', 'update_battery_charger');
        setValue('site_id', site_id);
        setValue('charger_id', data.id);
        setValue('float_voltage', data.float_voltage);
        setValue('float_current', data.float_current);
        setValue('boost_voltage', data.boost_voltage);
        setValue('boots_current', data.boots_current);
        setValue('electrolyte', data.electrolyte);
        setValue('remarks', data.remarks);

        setBatteryChargerModal(true);
    }
    // Transformer Checklist Update 
    async function transformerChecklistUpdate(checklist) {
        console.log(checklist);
        setValue('site_id', site_id);
        setValue('type', 'transformers');
        setValue('tabname', 'update_site_checklist');
        setValue('checklist[]', checklist);
        setTransformersChecklistModal(true);
    }
    // Transformer Add 
    async function transformerAdd() {
        setValue('trans_id', 0);
        setValue('site_id', site_id);
        setValue('tabname', 'add_transformer');

        setValue('name', '');
        setValue('type', '');
        setValue('make_model', '');
        setValue('rating', '');
        setValue('serial_no', '');
        setValue('rating', '');
        setValue('status', 'Normal');
        setValue('transreading', '');
        setValue('manufacture_year', '');

        setTransformersModal(true);
    }
    
    // Transformer Update 
    async function transformerUpdate(data) {
        setValue('trans_id', data.id);
        setValue('site_id', site_id);
        setValue('tabname', 'edit_transformer');

        setValue('name', data.name);
        setValue('type', data.type);
        setValue('make_model', data.make_model);
        setValue('rating', data.rating);
        setValue('serial_no', data.serial_no);
        setValue('status', data.status);
        setValue('manufacture_year', data.manufacture_year);
        setValue('checklist', data.checklist);

        
        if(data.transreading && data.transreading.length>0)
        {
            var transreading = JSON.parse(data.transreading);
            setValue(`transreading[oil_operat]`, transreading.oil_operat);
            setValue(`transreading[oil_alarm]`, transreading.oil_alarm);
            setValue(`transreading[oil_trip]`, transreading.oil_trip);
            setValue(`transreading[wind_operat]`, transreading.wind_operat);
            setValue(`transreading[wind_alarm]`, transreading.wind_alarm);
            setValue(`transreading[wind_trip]`, transreading.wind_trip);
            setValue(`transreading[ambient]`, transreading.ambient);
        }else{
            setValue('transreading', '');
        }

        setTransformersModal(true);
    }

    // HT Switchgear Checklist Update 
    async function switchgearsChecklistUpdate(type, checklist) {
        setValue('tabname', 'update_site_checklist');
        setValue('site_id', site_id);
        setValue('type', type);
        setValue('checklist[]', checklist);
        setSwitchgearsChecklistModal(true);
    }

    // Switchgears Add 
    async function switchgearAdd(type) {
        setPanelType(type);
        setPanelNo(1);
        setValue('trans_id', 0);
        setValue('site_id', site_id);
        setValue('tabname', 'add_switchgear');
        setValue('panelreadings', '');

        setValue('type', type);
        setValue('name', '');
        setValue('panels_no', 1);
        setValue('make_model', '');
        setValue('commissioning', 0);
        setValue('pg_cabel_inspection_status', 'Normal');

        setSwitchgearsModal(true);
    }
    // Switchgears Update 
    async function switchgearsUpdate(data) {
        setPanelType(data.type);
        setPanelNo(data.panels_no);
        setValue('switch_id', data.id);
        setValue('site_id', site_id);
        setValue('tabname', 'edit_switchgear');
        
        setValue('type', data.type);
        setValue('name', data.name);
        setValue('panels_no', data.panels_no);
        setValue('make_model', data.make_model);
        setValue('commissioning', data.commissioning);
        setValue('status', data.status);
        setValue('pg_cabel_inspection_status', data.pg_cabel_inspection_status);
        setValue('pg_cabel_inspection_remark', data.pg_cabel_inspection_remark); 
        setValue('checklist', data.checklist);
        
        if(data.panelreadings && data.panelreadings.length>0)
        {
            if(data.type === 'HT'){
                data.panelreadings.map((panelreadings, key) => {
                    setValue(`panelreadings[${key}][pname]`, panelreadings['pname']);
                    setValue(`panelreadings[${key}][v1]`, panelreadings['v1']);
                    setValue(`panelreadings[${key}][v2]`, panelreadings['v2']);
                    setValue(`panelreadings[${key}][v3]`, panelreadings['v3']);
                    setValue(`panelreadings[${key}][a1]`, panelreadings['a1']);
                    setValue(`panelreadings[${key}][a2]`, panelreadings['a2']);
                    setValue(`panelreadings[${key}][a3]`, panelreadings['a3']);
                    setValue(`panelreadings[${key}][p1]`, panelreadings['p1']);
                    setValue(`panelreadings[${key}][p2]`, panelreadings['p2']);
                    setValue(`panelreadings[${key}][remark]`, panelreadings['remark']);
                    return panelreadings;
                });
            }else if(data.type === 'LT'){
                data.panelreadings.map((panelreadings, key) => {
                    setValue(`panelreadings[${key}][pname]`, panelreadings['pname']);
                    setValue(`panelreadings[${key}][v1]`, panelreadings['v1']);
                    setValue(`panelreadings[${key}][v2]`, panelreadings['v2']);
                    setValue(`panelreadings[${key}][v3]`, panelreadings['v3']);
                    setValue(`panelreadings[${key}][v4]`, panelreadings['v4']);
                    setValue(`panelreadings[${key}][v5]`, panelreadings['v5']);
                    setValue(`panelreadings[${key}][v6]`, panelreadings['v6']);
                    setValue(`panelreadings[${key}][l1]`, panelreadings['l1']);
                    setValue(`panelreadings[${key}][l2]`, panelreadings['l2']);
                    setValue(`panelreadings[${key}][l3]`, panelreadings['l3']);
                    setValue(`panelreadings[${key}][remark]`, panelreadings['remark']);
                    return panelreadings;
                });
            }
        }else{
            setValue('panelreadings', '');
        }
        setSwitchgearsModal(true);
    }

    function panelHandleChange(e){
        let panels = e.target.value ? e.target.value : 1;
        setPanelNo(panels);
    }

    async function onTransformerSubmit(formData) {
        let tabname = formData.tabname ? formData.tabname : 'edit_transformer';
        setIsSubmitting(true);
        saveFormData(formData, tabname);
    }

    async function onSwitchgearsSubmit(formData) {
        let tabname = formData.tabname ? formData.tabname : 'edit_switchgear';
        setIsSubmitting(true);
        saveFormData(formData, tabname);
    }

    async function saveFormData(data, tabname = '') {
        try {
            postFileData(`site/manage/${site_id}/${tabname}`, data, 'post', (responce)=> {
                if(responce.success){
                    toastNotify('success', responce.message);
                    setIsSubmitting(false);
                    hideConfirmationModal();
                    reload === false ? setReload(true) : setReload(false);
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                        setIsSubmitting(false);
                    }
                    else {
                        toastNotify('danger', responce.message);
                        setIsSubmitting(false);
                    }
                }
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
            setIsSubmitting(false);
        }
    };

    async function handleDelete(id, tabname){
        setId(id);
        setTabname(tabname);
        setDisplayConfirmationModal(true);
    }

    // Handle the actual deletion of the item
    const submitDelete = (id) => {
        deleteRequest(`site/action/delete/${id}/${tabname}`,(responce)=> {
            if(responce.success){
                toastNotify('success', responce.message);
                setTabname('');
                hideConfirmationModal();
                reload === false ? setReload(true) : setReload(false);
            }else{
                toastNotify('danger', responce.message);
            }
        }); 
    }

    const testsReload = (action = false) => {
        if(action === true){
            reload === false ? setReload(true) : setReload(false);
        }
    }

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
        setTransformersChecklistModal(false);
        setSwitchgearsChecklistModal(false);
        setPgCableChecklistModal(false);
        setTransformersModal(false);
        setSwitchgearsModal(false);
        setBatteryChargerModal(false);
    };

    return (
        <>
            <div className="tab-pane fade show active" role="tabpanel" id="accordion">
                {/* HT Switchgear Reading & Checklist */}
                { blockShow && <div className="row mt-3">
                    <div className="col-sm-12">
                        <div className="d-flex bg-white p-1 py-2 my-1">
                            <OverlayTrigger placement="top" overlay={<Tooltip> Click here to expand this section </Tooltip>}>
                                <button type="button" className="collapsed accordion-button my-0 me-2 fs-6 lh-1 border-0 bg-white shadow-none" data-bs-toggle="collapse" data-bs-target="#collapse1">
                                    HT Switchgear Reading & Checklist
                                </button> 
                            </OverlayTrigger> 
                            { (user && user.allowed && user.allowed.includes('site-checklist-update')) &&
                                <button className="btn btn-primary ms-auto px-3" onClick={() => switchgearsChecklistUpdate('ht_switchgear', (details.chicklist && details.chicklist.ht_switchgear) ? details.chicklist.ht_switchgear : null )}>Update</button>
                            }
                        </div> 
                        
                        <div className="bg-white collapse fade" id="collapse1" data-bs-parent="#accordion">
                            <div className="p-3 p-sm-4"> 
                                <table className="table table-bordered table-hover">
                                    <tbody>
                                        <tr className="bg-light">
                                            <th>Inspection Item</th>
                                            <th width="100" align="center">Status</th>
                                        </tr>
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Appearance</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Appearance") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Switchgear</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Switchgear") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Instrument & Indicating Lights</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Instrument & Indicating Lights") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Proper Labelling of Circuits</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Proper Labelling of Circuits") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Protective Relays</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Protective Relays") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Sign of Overheating</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Sign of Overheating") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Excessive Humming Detected on the ACBs, MCCBs, Busbars and Changeover</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Excessive Humming Detected on the ACBs, MCCBs, Busbars and Changeover") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Switches (ATS)</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Switches (ATS)") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Extension / Circuitries Added</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Extension / Circuitries Added") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Capacitor Banks / Contactors / Circuitry Cables</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Capacitor Banks / Contactors / Circuitry Cables") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Protection Against Liquid Spillage / Dead Rodents</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Protection Against Liquid Spillage / Dead Rodents") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Main Earthing Bar / Earth Pits</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Main Earthing Bar / Earth Pits") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Accessibility</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Accessibility") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Housekeeping / Cleanliness of Switchboard</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Housekeeping / Cleanliness of Switchboard") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Switchroom Lighting / Emergency Light Fixture</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Switchroom Lighting / Emergency Light Fixture") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">First-aid Instructions for Electric Shock</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("First-aid Instructions for Electric Shock") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Emergency Contact List</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Emergency Contact List") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Rubber Anti-Slip Insulated Safety Mat</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Rubber Anti-Slip Insulated Safety Mat") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Single Line Diagram</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Single Line Diagram") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Expiry Date of Fire Extinguisher</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Expiry Date of Fire Extinguisher") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Logbook Updates</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Logbook Updates") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Others</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.ht_switchgear) && details.chicklist.ht_switchgear.indexOf("Others") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                  </div>
                }
                {/* PG Cable Details */}
                { blockShow && <div className="row mt-2">
                    <div className="col-sm-12">
                        <div className="d-flex bg-white p-1 mb-1">
                            <OverlayTrigger placement="top" overlay={<Tooltip> Click here to expand this section </Tooltip>}>
                                <button type="button" className="accordion-button collapsed my-0 me-2 fs-6 lh-1 border-0 bg-white shadow-none" data-bs-toggle="collapse" data-bs-target="#collapse3a">
                                    PG Cable Checklist
                                </button> 
                            </OverlayTrigger>
                            { (user && user.allowed && user.allowed.includes('site-pg-cable-update')) && 
                                <button className="btn btn-primary ms-auto px-3" onClick={() => pgCableChecklistUpdate(details.chicklist && details.chicklist.pgcable ? details.chicklist.pgcable: null)}>Update</button>
                            }
                        </div>
                        <div className="bg-white collapse fade" id="collapse3a" data-bs-parent="#accordion">
                            <div className="p-3 p-sm-4">
                                <table className="table table-bordered table-hover">
                                    <tbody>
                                        <tr className="bg-light">
                                            <th align="center" width="180" className="text-center">Status</th>
                                            <th>Remarks</th>
                                        </tr>
                                        <tr>
                                            <td align="center">{ (details.chicklist && details.chicklist.pgcable && details.chicklist.pgcable.status) ? details.chicklist.pgcable.status : '' }</td>
                                            <td>
                                                { (details.chicklist && details.chicklist.pgcable && details.chicklist.pgcable.remarks) ? details.chicklist.pgcable.remarks : '' }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                 </div>
                }
                {/*  Battery Charger */} 
                { blockShow && <div className="row mt-2">
                    <div className="col-sm-12">
                        <div className="d-flex bg-white p-1 mb-1">
                            <OverlayTrigger placement="top" overlay={<Tooltip> Click here to expand this section </Tooltip>}>
                                <button type="button" className="accordion-button collapsed my-0 me-2 fs-6 lh-1 border-0 bg-white shadow-none" data-bs-toggle="collapse" data-bs-target="#collapse3b">
                                    Battery Charger
                                </button> 
                            </OverlayTrigger>
                            { (user && user.allowed && user.allowed.includes('site-battery-charger-manage')) &&
                                <button className="btn btn-primary ms-auto text-nowrap" onClick={() => pgBatteryChargerAdd()}>Add New</button>
                            }
                        </div>
                        <div className="row collapse fade" id="collapse3b" data-bs-parent="#accordion">
                            { 
                                ( details.chargers && details.chargers.length>0 ) ? details.chargers.map( (charger, ind) => 
                                    <div className="col-md-4 col-sm-6 mb-3" key={ind}>
                                        <div className="bg-white h-100 px-3 shadow-sm">
                                            <div className="d-flex border-bottom pt-2 pb-2 mb-1">
                                                <h6 className="mb-0 align-self-center text-capitalize">{ `Battery Charger ${ind+1}` }</h6>
                                                { (user && user.allowed && user.allowed.includes('site-battery-charger-manage')) &&
                                                    <div className="ms-auto">
                                                        <svg className="icon text-success fs-5 me-2" role="button" onClick={() => pgBatteryChargerUpdate(charger)}>
                                                            <use href="#icon_edit"></use>
                                                        </svg>
                                                        <svg className="icon text-danger fs-5" role="button" onClick={() => handleDelete(charger.id, 'Battery Chargers')}>
                                                            <use href="#icon_trash"></use>
                                                        </svg>
                                                    </div>
                                                }
                                            </div>
                                            <table className="table table-borderless small">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "180px" }}>Float Voltage (V):</td>
                                                        <td>{ charger.float_voltage ? charger.float_voltage : 'N/A'  }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Float Current (A):</td>
                                                        <td className="text-uppercase">{ charger.float_current ? charger.float_current : 'N/A'  }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Boost Voltage (V):</td>
                                                        <td>{ charger.boost_voltage ? charger.boost_voltage : 'N/A'  }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Boost Current (A):</td>
                                                        <td>{ charger.boots_current ? charger.boots_current : 'N/A'  }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Electrolyte / Terminal:</td>
                                                        <td>{ charger.electrolyte ? charger.electrolyte : 'N/A'  }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                                :
                                <div className="col-md-12 col-sm-12 mb-3">
                                    <div className="bg-white h-100 px-3 shadow-sm">
                                        <div className="d-flex pt-2 pb-2 mb-1">
                                            <small className="text-mutted">No record(s) found.</small>
                                        </div>
                                    </div>
                                </div>
                            } 
                        </div>
                    </div>
                 </div>
                }
                {/* HT Switchgear Information */}
                { blockShow &&  <div className="row mt-2">
                    <div className="col-sm-12">
                        <div className="d-flex bg-white p-1 py-2 mb-1">
                            <OverlayTrigger placement="top" overlay={<Tooltip> Click here to expand this section </Tooltip>}>
                                <button type="button" className="accordion-button collapsed my-0 me-2 fs-6 lh-1 border-0 bg-white shadow-none" data-bs-toggle="collapse" data-bs-target="#collapse4">
                                    HT Switchgear Information
                                </button> 
                            </OverlayTrigger>
                            { (user && user.allowed && user.allowed.includes('site-switchgear-manage')) &&  
                                <button className="btn btn-primary ms-auto text-nowrap"  onClick={() => switchgearAdd('HT')}>Add New</button>
                            }
                        </div>
                        <div className="row collapse fade" id="collapse4" data-bs-parent="#accordion">
                            { 
                                ( htSwitchgear && htSwitchgear.length >0 ) ? htSwitchgear.map((switchgear, ind) => 
                                    <div className="col-md-4 col-sm-6 mb-3" key={ind}>
                                      {switchgear.type === "HT" &&
                                        <div className="bg-white h-100 px-3 shadow-sm">
                                            <div className="d-flex border-bottom pt-2 pb-2 mb-1">
                                                <h6 className="mb-0 align-self-center">{ switchgear.name }</h6>
                                                { (user && user.allowed && user.allowed.includes('site-switchgear-manage')) && 
                                                    <div className="ms-auto">
                                                        <svg className="icon text-success fs-5 me-2" role="button" onClick={() => switchgearsUpdate(switchgear)}>
                                                            <use href="#icon_edit"></use>
                                                        </svg>
                                                        <svg className="icon text-danger fs-5" role="button" onClick={() => handleDelete(switchgear.id, 'switchgears')}>
                                                            <use href="#icon_trash"></use>
                                                        </svg>
                                                    </div>
                                                }
                                            </div>
                                            <table className="table table-borderless small">
                                                <tbody>   
                                                    <tr>
                                                        <td className="text-uppercase" style={{ width: "180px" }}>Make & Model:</td>
                                                        <td>{ switchgear.make_model }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Number of Panels:</td>
                                                        <td>{ switchgear.panels_no ? switchgear.panels_no : 0 }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Year of Commissioning:</td>
                                                        <td>{ switchgear.commissioning ? switchgear.commissioning : 0 }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Status:</td>
                                                        <td>{ switchgear.status === 'Issue' ? "Issue(s) found" : (switchgear.status ? switchgear.status: 'Normal')  }</td>
                                                    </tr>
                                                </tbody> 
                                            </table>
                                        </div>
                                      }
                                    </div>
                                   
                                ) :
                                <div className="col-md-12 col-sm-12 mb-3">
                                    <div className="bg-white h-100 px-3 shadow-sm">
                                        <div className="d-flex pt-2 pb-2 mb-1">
                                            <small className="text-mutted">No record(s) found.</small>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                  </div>
                }
                {/* Transformer Checklist */}
                { blockShow && <div className="row mt-2">
                    <div className="col-sm-12">
                        <div className="d-flex bg-white p-1 py-2 my-1">
                            <OverlayTrigger placement="top" overlay={<Tooltip> Click here to expand this section </Tooltip>}>
                                <button type="button" className="accordion-button collapsed my-0 me-2 fs-6 lh-1 border-0 bg-white shadow-none" data-bs-toggle="collapse" data-bs-target="#collapse2">
                                    Transformer Checklist
                                </button> 
                            </OverlayTrigger>
                            { (user && user.allowed && user.allowed.includes('site-checklist-update')) &&
                                <button className="btn btn-primary ms-auto px-3" onClick={() => transformerChecklistUpdate((details.chicklist && details.chicklist.transformers) ? details.chicklist.transformers : null)}>Update</button>
                            }
                        </div>
                        <div className="bg-white collapse fade" id="collapse2" data-bs-parent="#accordion">
                            <div className="p-3 p-sm-4">
                                <table className="table table-bordered table-hover">
                                    <tbody>
                                        <tr className="bg-light">
                                            <th>Inspection Item</th>
                                            <th width="100" align="center">Status</th>
                                        </tr>
                                        <tr>
                                            <td>Appearance</td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.transformers) && details.chicklist.transformers.indexOf("Appearance") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" />  }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Cables</td>
                                            <td align="center">
                                                {  (details.chicklist && details.chicklist.transformers) && details.chicklist.transformers.indexOf("Cables") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Signs of Leakage</td>
                                            <td align="center">
                                                {  (details.chicklist && details.chicklist.transformers) && details.chicklist.transformers.indexOf("Signs of Leakage") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Instrumentation & Indication</td>
                                            <td align="center">
                                                {  (details.chicklist && details.chicklist.transformers) && details.chicklist.transformers.indexOf("Instrumentation & Indication") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Labels</td>
                                            <td align="center">
                                                {  (details.chicklist && details.chicklist.transformers) && details.chicklist.transformers.indexOf("Labels") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Electrical Danger Signs with appropriate voltage</td>
                                            <td align="center">
                                                {  (details.chicklist && details.chicklist.transformers) && details.chicklist.transformers.indexOf("Electrical Danger Signs with appropriate voltage") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Expiry Date of Fire Extinguisher</td>
                                            <td align="center">
                                                {  (details.chicklist && details.chicklist.transformers) && details.chicklist.transformers.indexOf("Expiry Date of Fire Extinguisher") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Others</td>
                                            <td align="center">
                                                {  (details.chicklist && details.chicklist.transformers) && details.chicklist.transformers.indexOf("Others") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                 </div>
                }
                {/* Transformers Information  */}
                { blockShow && <div className="row mt-2">
                    <div className="col-sm-12">
                        <div className="d-flex bg-white p-1 mb-1">
                            <OverlayTrigger placement="top" overlay={<Tooltip> Click here to expand this section </Tooltip>}>
                                <button type="button" className="accordion-button collapsed my-0 me-2 fs-6 lh-1 border-0 bg-white shadow-none" data-bs-toggle="collapse" data-bs-target="#collapse5">
                                    Transformers
                                </button> 
                            </OverlayTrigger>
                            { (user && user.allowed && user.allowed.includes('site-transformer-manage')) &&
                                <button className="btn btn-primary ms-auto text-nowrap" onClick={() => transformerAdd()}>Add New</button>
                            }
                        </div>
                        <div className="row collapse fade" id="collapse5" data-bs-parent="#accordion">
                            { 
                                ( details.transformers && details.transformers.length>0 ) ? details.transformers.map( (transformer, ind) => 
                                    <div className="col-md-4 col-sm-6 mb-3" key={ind}>
                                        <div className="bg-white h-100 px-3 shadow-sm">
                                            <div className="d-flex border-bottom pt-2 pb-2 mb-1">
                                                <h6 className="mb-0 align-self-center text-capitalize">{ transformer.name }</h6>
                                                { (user && user.allowed && user.allowed.includes('site-transformer-manage')) &&
                                                    <div className="ms-auto">
                                                        <svg className="icon text-success fs-5 me-2" role="button" onClick={() => transformerUpdate(transformer)}>
                                                            <use href="#icon_edit"></use>
                                                        </svg>
                                                        <svg className="icon text-danger fs-5" role="button" onClick={() => handleDelete(transformer.id, 'transformer')}>
                                                            <use href="#icon_trash"></use>
                                                        </svg>
                                                    </div>
                                                }
                                            </div>
                                            <table className="table table-borderless small">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "180px" }}>Type:</td>
                                                        <td>{ transformer.type ? transformer.type : 'N/A'  }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Make & Model:</td>
                                                        <td className="text-uppercase">{ transformer.make_model ? transformer.make_model : 'N/A'  }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rating (kVA):</td>
                                                        <td>{ transformer.rating ? transformer.rating : '0'  }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Manufacture Year:</td>
                                                        <td>{ transformer.manufacture_year ? transformer.manufacture_year : 'N/A'  }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Status:</td>
                                                        <td>{ transformer.status === 'Issue' ? "Issue(s) found" : (transformer.status ? transformer.status: 'Normal')  }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                                :
                                <div className="col-md-12 col-sm-12 mb-3">
                                    <div className="bg-white h-100 px-3 shadow-sm">
                                        <div className="d-flex pt-2 pb-2 mb-1">
                                            <small className="text-mutted">No record(s) found.</small>
                                        </div>
                                    </div>
                                </div>
                            } 
                        </div>
                    </div>
                 </div>
                }
                {/* LT Switchgear Readings & Checklist */}
                <div className="row mt-2">
                    <div className="col-sm-12">
                        <div className="d-flex bg-white p-1 py-2 my-1">
                            <OverlayTrigger placement="top" overlay={<Tooltip> Click here to expand this section </Tooltip>}>
                                <button type="button" className="collapsed accordion-button my-0 me-2 fs-6 lh-1 border-0 bg-white shadow-none" data-bs-toggle="collapse" data-bs-target="#collapse6">
                                    LT Switchgear Readings & Checklist
                                </button> 
                            </OverlayTrigger>
                            { (user && user.allowed && user.allowed.includes('site-checklist-update')) &&
                                <button className="btn btn-primary ms-auto px-3" onClick={() => switchgearsChecklistUpdate('lt_switchgear', (details.chicklist && details.chicklist.lt_switchgear) ? details.chicklist.lt_switchgear : null)}>Update</button>
                            }
                        </div> 
                        <div className="bg-white collapse fade" id="collapse6" data-bs-parent="#accordion">
                            <div className="p-3 p-sm-4">
                                <table className="table table-bordered table-hover">
                                    <tbody>
                                        <tr className="bg-light">
                                            <th>Inspection Item</th>
                                            <th width="100" align="center">Status</th>
                                        </tr>
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Appearance</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Appearance") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Switchgear</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Switchgear") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Instrument & Indicating Lights</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Instrument & Indicating Lights") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Proper Labelling of Circuits</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Proper Labelling of Circuits") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Protective Relays</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Protective Relays") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Sign of Overheating</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Sign of Overheating") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Excessive Humming Detected on the ACBs, MCCBs, Busbars and Changeover</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Excessive Humming Detected on the ACBs, MCCBs, Busbars and Changeover") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Switches (ATS)</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Switches (ATS)") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Extension / Circuitries Added</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Extension / Circuitries Added") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Capacitor Banks / Contactors / Circuitry Cables</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Capacitor Banks / Contactors / Circuitry Cables") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Protection Against Liquid Spillage / Dead Rodents</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Protection Against Liquid Spillage / Dead Rodents") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Main Earthing Bar / Earth Pits</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Main Earthing Bar / Earth Pits") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Accessibility</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Accessibility") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Housekeeping / Cleanliness of Switchboard</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Housekeeping / Cleanliness of Switchboard") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Switchroom Lighting / Emergency Light Fixture</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Switchroom Lighting / Emergency Light Fixture") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">First-aid Instructions for Electric Shock</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("First-aid Instructions for Electric Shock") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Emergency Contact List</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Emergency Contact List") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Rubber Anti-Slip Insulated Safety Mat</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Rubber Anti-Slip Insulated Safety Mat") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Single Line Diagram</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Single Line Diagram") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Expiry Date of Fire Extinguisher</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Expiry Date of Fire Extinguisher") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Logbook Updates</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Logbook Updates") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                        <tr>
                                            <td align="left">
                                                <label className="form-check-label">Others</label>
                                            </td>
                                            <td align="center">
                                                { (details.chicklist && details.chicklist.lt_switchgear) && details.chicklist.lt_switchgear.indexOf("Others") > -1 ? <img className="checkImg" src="/images/tick.png" alt="" /> : <img className="checkImg" src="/images/cross.png" alt="" /> }
                                            </td>
                                        </tr>     
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>  
                {/* LT Switchgear Information */}
                <div className="row mt-2">
                    <div className="col-sm-12">
                        <div className="d-flex bg-white p-1 py-2 mb-1">
                            <OverlayTrigger placement="top" overlay={<Tooltip> Click here to expand this section </Tooltip>}>
                                <button type="button" className="accordion-button collapsed my-0 me-2 fs-6 lh-1 border-0 bg-white shadow-none" data-bs-toggle="collapse" data-bs-target="#collapse7">
                                    LT Switchgear Information
                                </button> 
                            </OverlayTrigger>
                            { (user && user.allowed && user.allowed.includes('site-switchgear-manage')) && 
                                <button className="btn btn-primary ms-auto text-nowrap"  onClick={() => switchgearAdd('LT')}>Add New</button>
                            }
                        </div>
                        <div className="row collapse fade" id="collapse7" data-bs-parent="#accordion">
                            { 
                                ( ltSwitchgear && ltSwitchgear.length>0) ? ltSwitchgear.map( (switchgear, ind) => 
                                   <div className="col-md-4 col-sm-6 mb-3" key={ind}>
                                    {switchgear.type === "LT" &&
                                        <div className="bg-white h-100 px-3 shadow-sm">
                                            <div className="d-flex border-bottom pt-2 pb-2 mb-1">
                                                <h6 className="mb-0 align-self-center">{ switchgear.name }</h6>
                                                { (user && user.allowed && user.allowed.includes('site-switchgear-manage')) && 
                                                    <div className="ms-auto">
                                                        <svg className="icon text-success fs-5 me-2" role="button" onClick={() => switchgearsUpdate(switchgear)}>
                                                            <use href="#icon_edit"></use>
                                                        </svg>
                                                        <svg className="icon text-danger fs-5" role="button" onClick={() => handleDelete(switchgear.id, 'switchgears')}>
                                                            <use href="#icon_trash"></use>
                                                        </svg>
                                                    </div>
                                                }
                                            </div>
                                            <table className="table table-borderless small">
                                                <tbody>   
                                                    <tr>
                                                        <td style={{ width: "180px" }}>Make & Model:</td>
                                                        <td className="text-uppercase">{ switchgear.make_model }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Number of Panels:</td>
                                                        <td>{ switchgear.panels_no ? switchgear.panels_no : 0 }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Year of Commissioning:</td>
                                                        <td>{ switchgear.commissioning ? switchgear.commissioning : 0 }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Status:</td>
                                                        <td>{ switchgear.status === 'Issue' ? "Issue(s) found" : (switchgear.status ? switchgear.status: 'Normal')  }</td>
                                                    </tr>
                                                </tbody> 
                                            </table>
                                        </div>
                                    }
                                    </div>
                                ) :
                                <div className="col-md-12 col-sm-12 mb-3">
                                    <div className="bg-white h-100 px-3 shadow-sm">
                                        <div className="d-flex pt-2 pb-2 mb-1">
                                            <small className="text-mutted">No record(s) found.</small>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/* Tests */}
                { (user && user.allowed && user.allowed.includes('tests-view')) && 
                    <div className="row mt-2">
                        <div className="col-sm-12">
                            <div className="d-flex bg-white p-1 py-2 mb-4">
                                <OverlayTrigger placement="top" overlay={<Tooltip> Click here to expand this section </Tooltip>}>
                                    <button type="button" className="accordion-button collapsed my-0 me-2 p-2 fs-6 lh-1 border-0 bg-white shadow-none" data-bs-toggle="collapse" data-bs-target="#collapse8">
                                        Tests
                                    </button> 
                                </OverlayTrigger>
                            </div>
                            <div className="row collapse fade show" id="collapse8" data-bs-parent="#accordion">
                                <TestReports siteId={site_id} details={details.tests} user={user} page="site_view" confirmAction={testsReload} />
                            </div>
                        </div>
                    </div>
                }
            </div>

            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} id={id} message={'Are you sure you want to delete this record?'}  />

            { transformersModal && <Modal size='lg' show={transformersModal} onHide={hideConfirmationModal} className="modal" id="update_transformers">
                    <div className="modal-header">
                        <h5 className="modal-title">Add/Update Transformer</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                    </div>
                    <div className="modal-body">
                        <Form id='updateTransformersForm' name='updateTransformersForm' onSubmit={handleSubmit(onTransformerSubmit)}>
                        
                            <Form.Control type="hidden" name="tabname" { ...register('tabname') } defaultValue='edit_transformer' />
                            <Form.Control type="hidden" name="site_id" { ...register('site_id') } defaultValue={site_id} />
                            <Form.Control type="hidden" name="trans_id" { ...register('trans_id') } />
                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="name" className="col-form-label">Name<strong className="text-danger"> *</strong></Form.Label>
                                <div className="">
                                    <Form.Control type="text" name="name" { ...register('name',{ required: true}) } className={errors.name && errors.name.type === "required" ? 'is-invalid form-control' : 'form-control' } />
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center">

                                <div className="col-sm-12 col-md-6">
                                    <Form.Label htmlFor="Type" className="col-form-label">Type<strong className="text-danger"> *</strong></Form.Label>
                                    <div className="">
                                        <Form.Select name="type" { ...register('type',{ required: true}) } className={errors.type && errors.type.type === "required" ? 'is-invalid form-select' : 'form-select' }>
                                            <option value="">Please select</option>
                                            <option value="Dry">Dry</option>
                                            <option value="Silicone Oil">Silicone Oil</option>
                                            <option value="Mineral Oil">Mineral Oil</option>
                                            <option value="Others">Others</option>
                                        </Form.Select>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <Form.Label htmlFor="make_model" className="col-form-label">Make & Model<strong className="text-danger"> *</strong></Form.Label>
                                    <div className="">
                                        <Form.Control type="text" name="make_model" { ...register('make_model',{ required: true}) } className={errors.make_model && errors.make_model.type === "required" ? 'is-invalid form-control text-uppercase' : 'form-control text-uppercase' } />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <Form.Label htmlFor="rating" className="col-form-label">Rating (kVA)<strong className="text-danger"> *</strong></Form.Label>
                                    <div className="">
                                        <Form.Control type="text" name="rating" { ...register('rating',{ required: true}) } className={errors.rating && errors.rating.type === "required" ? 'is-invalid form-control' : 'form-control' } />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <Form.Label htmlFor="manufacture_year" className="col-form-label">Manufacture Year<strong className="text-danger"> *</strong></Form.Label>
                                    <div className="">
                                        <Form.Control type="number" name="manufacture_year" { ...register('manufacture_year', { required: true}) } className={errors.manufacture_year && errors.manufacture_year.type === "required" ? 'is-invalid form-control' : 'form-control' } maxLength={4} placeholder="YYYY" />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <Form.Label htmlFor="serial_no" className="col-form-label">Serial No.</Form.Label>
                                    <div className="">
                                        <Form.Control type="text" name="serial_no" { ...register('serial_no') } className={errors.serial_no && errors.serial_no.type === "required" ? 'is-invalid form-control text-uppercase' : 'form-control text-uppercase' } />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <Form.Label htmlFor="status" className="col-form-label">Status</Form.Label>
                                    <div className="">
                                        <Form.Select name="status" { ...register('status') } className={errors.status && errors.status.type === "required" ? 'is-invalid form-select' : 'form-select' }>
                                            <option value="Normal">Normal</option>
                                            <option value="Issue">Issue(s) found</option>
                                            <option value="Others">Others</option>
                                        </Form.Select>
                                    </div>
                                </div>

                            </div>
                            <div className="row mb-1 justify-content-center">
                                <div className="col-md-12">
                                    <div className="table-responsive" role="tabpanel">
                                        <p className="mb-0 mt-20px"></p>
                                        <table className="table-records text-left">
                                            <thead>
                                                <tr>
                                                    <th colSpan='3' className="light-gray text-center">Oil Temperature (°C)</th>
                                                    <th colSpan='3' className="light-gray text-center">Winding Temperature (°C)</th>
                                                    <th rowSpan='2' className="light-gray text-center">Ambient Temperature(°C)</th>
                                                </tr>
                                                <tr>
                                                    <th className="light-gray text-center">Operating</th>
                                                    <th className="light-gray text-center">Alarm</th>
                                                    <th className="light-gray text-center">Trip</th>
                                                    <th className="light-gray text-center">Operating</th>
                                                    <th className="light-gray text-center">Alarm</th>
                                                    <th className="light-gray text-center">Trip</th>
                                                </tr> 
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Form.Control placeholder="Operating" type="text" { ...register(`transreading[oil_operat]`) } className="form-control fs-12px" maxLength={32} />
                                                    </td>
                                                    <td>
                                                        <Form.Control placeholder="Alarm" type="text" { ...register(`transreading[oil_alarm]`) } className="form-control fs-12px" maxLength={32} />
                                                    </td>
                                                    <td>
                                                        <Form.Control placeholder="Trip" type="text" { ...register(`transreading[oil_trip]`) } className="form-control fs-12px" maxLength={32} />
                                                    </td> 
                                                    <td>
                                                        <Form.Control placeholder="Operating" type="text" { ...register(`transreading[wind_operat]`) } className="form-control fs-12px" maxLength={32} />
                                                    </td> 
                                                    <td>
                                                        <Form.Control placeholder="Alarm" type="text" { ...register(`transreading[wind_alarm]`) } className="form-control fs-12px" maxLength={32} />
                                                    </td>
                                                    <td>
                                                        <Form.Control placeholder="Trip" type="text" { ...register(`transreading[wind_trip]`) } className="form-control fs-12px" maxLength={32} />
                                                    </td>
                                                    <td>
                                                        <Form.Control placeholder="Ambient" type="text" { ...register(`transreading[ambient]`) } className="form-control fs-12px" maxLength={32} />
                                                    </td>
                                                </tr> 
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-1 justify-content-center mt-4">
                                <div className="col-md-12">
                                    <div className="text-end">
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                                            {isSubmitting && <span className="spinner-border spinner-border-sm sm-2"></span>}
                                            &nbsp; Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal>
            }
            { switchgearsModal && <Modal size="lg" show={switchgearsModal} onHide={hideConfirmationModal} className="modal" id="update_switchers">
                    <div className="modal-header">
                        <h5 className="modal-title">Add/Update Switchgears</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                    </div>
                    <div className="modal-body">
                        <Form id='updateSwitchgearsForm' name='updateSwitchgearsForm' onSubmit={handleSubmit(onSwitchgearsSubmit)}>
                            <Form.Control type="hidden" name="tabname" { ...register('tabname') } defaultValue='edit_switchgear' />
                            <Form.Control type="hidden" name="site_id" { ...register('site_id') } defaultValue={site_id} />
                            <Form.Control type="hidden" name="type" { ...register('type') } defaultValue="HT" />
                            <Form.Control type="hidden" name="switch_id" { ...register('switch_id') } />
                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="Type" className="col-form-label">Name<strong className="text-danger"> *</strong></Form.Label>
                                <div className="">
                                    <Form.Control type="text" name="name" { ...register('name',{ required: true}) } className={errors.name && errors.name.type === "required" ? 'is-invalid form-control' : 'form-control' } />
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="Type" className="col-form-label">Make & Model<strong className="text-danger"> *</strong></Form.Label>
                                <div className="">
                                    <Form.Control type="text" name="make_model" { ...register('make_model',{ required: true}) } className={errors.make_model && errors.make_model.type === "required" ? 'is-invalid form-control text-uppercase' : 'form-control text-uppercase' } />
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="Type" className="col-form-label">Number of Panels<strong className="text-danger"> *</strong></Form.Label>
                                <div className="">
                                    <Form.Control type="number" name="panels_no" onKeyUp={(e) => panelHandleChange(e)} { ...register('panels_no',{ required: true, maxLength: 5 }) } className={errors.panels_no && errors.panels_no.type === "required" ? 'is-invalid form-control text-uppercase' : 'form-control text-uppercase' } maxLength={5} />
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="Type" className="col-form-label">Year of Commissionin</Form.Label>
                                <div className="">
                                    <Form.Control type="number" name="commissioning" { ...register('commissioning') } className={errors.commissioning && errors.commissioning.type === "required" ? 'is-invalid form-control' : 'form-control' } maxLength={7} />
                                </div>
                            </div> 
                            <div className="row mb-1 justify-content-center">
                                <div className="col-md-12">
                                    { panelType === 'HT' && <div className="table-responsive" role="tabpanel">
                                        <p className="mb-0 mt-20px"><strong>HT Panel Readings</strong></p>
                                        <table className="table-records text-left">
                                            <thead>
                                                <tr>
                                                    <th rowSpan="2" className="light-gray text-center">Panel Name</th>
                                                    <th colSpan="3" className="light-gray text-center tb-10px">Voltmeter (kV)</th>
                                                    <th colSpan="3" className="light-gray text-center tb-10px">Ammeter (A)</th>
                                                    <th colSpan="2" className="light-gray text-center tb-10px">SF 6 Pressure*</th>
                                                    <th rowSpan="2" className="light-gray text-center">Remarks</th>
                                                </tr>
                                                <tr>
                                                    <th className="light-gray text-center">1-2</th>
                                                    <th className="light-gray text-center">2-3</th>
                                                    <th className="light-gray text-center">3-1</th>
                                                    <th className="light-gray text-center">L1</th>
                                                    <th className="light-gray text-center">L2</th>
                                                    <th className="light-gray text-center">L3</th>
                                                    <th className="light-gray text-center">BB</th>
                                                    <th className="light-gray text-center">VCB</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    range(panelNo).map((panel_number, key) => (     
                                                        <tr key={key}>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="Name" type="text" { ...register(`panelreadings[${key}][pname]`) } className="form-control fs-12px" maxLength={32} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="1-2" type="text" { ...register(`panelreadings[${key}][v1]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="2-3" type="text" { ...register(`panelreadings[${key}][v2]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="3-1" type="text" { ...register(`panelreadings[${key}][v3]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="L1" type="text" { ...register(`panelreadings[${key}][a1]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="L2" type="text" { ...register(`panelreadings[${key}][a2]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="L3" type="text" { ...register(`panelreadings[${key}][a3]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="BB" type="text" { ...register(`panelreadings[${key}][p1]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="VCB" type="text" { ...register(`panelreadings[${key}][p2]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="Status" type="text" { ...register(`panelreadings[${key}][remark]`) } className="form-control fs-12px" maxLength={32} />
                                                            </td>
                                                        </tr> 
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <p className="mt-0 fs-12px">* SF 6 Pressure must be between 0.2 and 0.9.</p>
                                    </div>
                                    }
                                    { panelType === 'LT' && <div className="table-responsive" role="tabpanel">
                                        <p className="mb-0 mt-20px"><strong>LT Panel Readings</strong></p>
                                        <table className="table-records text-left">
                                            <thead>
                                                <tr>
                                                    <th rowSpan="2" className="light-gray text-center">Panel Name</th>
                                                    <th colSpan="6" className="light-gray text-center tb-10px">Voltmeter (V)</th>
                                                    <th colSpan="3" className="light-gray text-center tb-10px">Ammeter (A)</th>
                                                    <th rowSpan="2" className="light-gray text-center">Remarks</th>
                                                </tr>
                                                <tr>
                                                    <th className="light-gray text-center">V1-2</th>
                                                    <th className="light-gray text-center">V2-3</th>
                                                    <th className="light-gray text-center">V3-1</th>
                                                    <th className="light-gray text-center">V1-N</th>
                                                    <th className="light-gray text-center">V2-N</th>
                                                    <th className="light-gray text-center">V3-N</th>
                                                    <th className="light-gray text-center">L1</th>
                                                    <th className="light-gray text-center">L2</th>
                                                    <th className="light-gray text-center">L3</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    range(panelNo).map((panel_number, key) => (     
                                                        <tr key={key}>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="Name" type="text" { ...register(`panelreadings[${key}][pname]`) } className="form-control fs-12px" maxLength={32} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="V1-2" type="text" { ...register(`panelreadings[${key}][v1]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="V2-3" type="text" { ...register(`panelreadings[${key}][v2]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="V3-1" type="text" { ...register(`panelreadings[${key}][v3]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="V1-N" type="text" { ...register(`panelreadings[${key}][v4]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="V2-N" type="text" { ...register(`panelreadings[${key}][v5]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="V3-N" type="text" { ...register(`panelreadings[${key}][v6]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="L1" type="text" { ...register(`panelreadings[${key}][l1]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="L2" type="text" { ...register(`panelreadings[${key}][l2]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="L3" type="text" { ...register(`panelreadings[${key}][l3]`) } className="form-control fs-12px arrow-hide" maxLength={5} />
                                                            </td>
                                                            <td className="text-center">
                                                                <Form.Control placeholder="Remark" type="text" { ...register(`panelreadings[${key}][remark]`) } className="form-control fs-12px" maxLength={32} />
                                                            </td>
                                                        </tr> 
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <p className="mt-0 fs-12px">* SF 6 Pressure must be between 0.2 and 0.9.</p>
                                    </div>
                                    }
                                </div>
                            </div> 
                            <div className="row mb-1 justify-content-center mt-3">
                                <div className="col-md-12">
                                    <div className="text-end">
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                                            {isSubmitting && <span className="spinner-border spinner-border-sm sm-2"></span>}
                                            &nbsp; Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal>
            }
            { transformersChecklistModal && <Modal show={transformersChecklistModal} onHide={hideConfirmationModal} className="modal" id="update_transformers">
                <div className="modal-header">
                    <h5 className="modal-title">Transformer Checklist</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <form id='transformerChecklistUpdateForm' name='transformerChecklistUpdateForm' onSubmit={handleSubmit(onTransformerSubmit)} className="checklist-from">
                        <Form.Control type="hidden" name="tabname" { ...register('tabname') } defaultValue='update_site_checklist' />
                        <Form.Control type="hidden" name="site_id" { ...register('site_id') } defaultValue={site_id} />
                        <Form.Control type="hidden" name="type" { ...register('type') } defaultValue="transformers" />
                        <div className="row mb-1 mt-3 justify-content-cente">
                            <div className="col-md-12">
                                <div className="mt-25px page-break">
                                    <table className="table-records td-padding-10">
                                       <tbody>
                                            <tr>
                                                <th align="left" bgcolor="#ddd">Inspection Item</th>
                                                <th bgcolor="#ddd" align="center">Status</th>
                                            </tr>
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt1">Appearance</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt1" value="Appearance"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt2">Cables</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt2" value="Cables"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt3">Signs of Leakage</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt3" value="Signs of Leakage"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt4">Instrumentation & Indication</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt4" value="Instrumentation & Indication"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt5">Labels</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt5" value="Labels"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt6">Electrical Danger Signs with appropriate voltage</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt6" value="Electrical Danger Signs with appropriate voltage"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt7">Expiry Date of Fire Extinguisher</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt7" value="Expiry Date of Fire Extinguisher"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt8">Others</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt8" value="Others"/>
                                                </td>
                                            </tr> 
                                        </tbody>    
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-1 justify-content-center mt-3">
                            <div className="col-md-12">
                                <div className="text-end">
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && <span className="spinner-border spinner-border-sm sm-2"></span>}
                                        &nbsp; Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            }
            { switchgearsChecklistModal && <Modal size='lg' show={switchgearsChecklistModal} onHide={hideConfirmationModal} className="modal" id="update_transformers">
                <div className="modal-header">
                    <h5 className="modal-title">HT Switchgear Reading & Checklist</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <Form id='switchgearChecklistUpdateForm' name='switchgearChecklistUpdateForm' onSubmit={handleSubmit(onSwitchgearsSubmit)} className="checklist-from">
                        <Form.Control type="hidden" name="tabname" { ...register('tabname') } defaultValue='update_site_checklist' />
                        <Form.Control type="hidden" name="site_id" { ...register('site_id') } defaultValue={site_id} />
                        <Form.Control type="hidden" name="type" { ...register('type') } defaultValue="ht_switchgear" />
                        <div className="row mb-1 justify-content-cente">
                            <div className="col-md-12">
                                <div className="mt-25px page-break">
                                    <table className="table-records td-padding-5">
                                        <tbody>
                                            <tr>
                                                <th align="left" bgcolor="#ddd">Inspection Item</th>
                                                <th bgcolor="#ddd" align="center">Status</th>
                                            </tr>
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt1">Appearance</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt1" value="Appearance"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt2">Switchgear</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt2" value="Switchgear"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt3">Instrument & Indicating Lights</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt3" value="Instrument & Indicating Lights"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt4">Proper Labelling of Circuits</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check  type="switch" name='checklist[]' {...register('checklist[]')} id="opt4" value="Proper Labelling of Circuits"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt5">Protective Relays</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt5" value="Protective Relays"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt6">Sign of Overheating</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt6" value="Sign of Overheating"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt7">Excessive Humming Detected on the ACBs, MCCBs, Busbars and Changeover</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt7" value="Excessive Humming Detected on the ACBs, MCCBs, Busbars and Changeover"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt8">Switches (ATS)</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt8" value="Switches (ATS)"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt9">Extension / Circuitries Added</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt9" value="Extension / Circuitries Added"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt10">Capacitor Banks / Contactors / Circuitry Cables</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt10" value="Capacitor Banks / Contactors / Circuitry Cables"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt11">Protection Against Liquid Spillage / Dead Rodents</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt11" value="Protection Against Liquid Spillage / Dead Rodents"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt12">Main Earthing Bar / Earth Pits</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt12" value="Main Earthing Bar / Earth Pits"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt13">Accessibility</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt13" value="Accessibility"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt14">Housekeeping / Cleanliness of Switchboard</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt14" value="Housekeeping / Cleanliness of Switchboard"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt15">Switchroom Lighting / Emergency Light Fixture</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt15" value="Switchroom Lighting / Emergency Light Fixture"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt16">First-aid Instructions for Electric Shock</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt16" value="First-aid Instructions for Electric Shock"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt17">Emergency Contact List</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt17" value="Emergency Contact List"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt18">Rubber Anti-Slip Insulated Safety Mat</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt18" value="Rubber Anti-Slip Insulated Safety Mat"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt19">Single Line Diagram</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt19" value="Single Line Diagram"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt20">Expiry Date of Fire Extinguisher</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt20" value="Expiry Date of Fire Extinguisher"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt21">Logbook Updates</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt21" value="Logbook Updates"/>
                                                </td>
                                            </tr>     
                                            <tr>
                                                <td align="left">
                                                    <Form.Label className="form-check-label" htmlFor="opt22">Others</Form.Label>
                                                </td>
                                                <td align="center">
                                                    <Form.Check type="switch" name='checklist[]' {...register('checklist[]')} id="opt22" value="Others"/>
                                                </td>
                                            </tr>   
                                        </tbody>  
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-1 justify-content-center mt-3">
                            <div className="col-md-12">
                                <div className="text-end">
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && <span className="spinner-border spinner-border-sm sm-2"></span>}
                                        &nbsp; Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
            }
            { pgCableChecklistModal && <Modal show={pgCableChecklistModal} onHide={hideConfirmationModal} className="modal" id="update_transformers">
                <div className="modal-header">
                    <h5 className="modal-title">PG Cable Checklist</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <Form id='pgcableChecklistUpdateForm' name='pgcableChecklistUpdateForm' onSubmit={handleSubmit(onSwitchgearsSubmit)}>
                        <Form.Control type="hidden" name="tabname" { ...register('tabname') } defaultValue='update_site_checklist' />
                        <Form.Control type="hidden" name="site_id" { ...register('site_id') } defaultValue={site_id} />
                        <Form.Control type="hidden" name="type" { ...register('type') } defaultValue="pgcable" />
                        
                        <div className="row mb-1 justify-content-center">
                            <Form.Label htmlFor="status" className="col-form-label">Status</Form.Label>
                            <div className="">
                                <Form.Select name="status" { ...register('status') } className={errors.status && errors.status.type === "required" ? 'is-invalid form-select' : 'form-select' }>
                                    <option value="Normal">Normal</option>
                                    <option value="Issue">Issue(s) found</option>
                                    <option value="Others">Others</option>
                                </Form.Select>
                            </div>
                        </div>

                        <div className="row mb-1 justify-content-center">
                            <Form.Label htmlFor="remarks" className="col-form-label">Remarks</Form.Label>
                            <div className="">
                                <Form.Control as="textarea" name="remarks" {...register('remarks')} rows="3" placeholder='Write here...' className={errors.remarks && errors.remarks.type === "required" ? 'is-invalid' : '' }></Form.Control>
                            </div>
                        </div>

                        <div className="row mb-1 justify-content-center mt-3">
                            <div className="col-md-12">
                                <div className="text-end">
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && <span className="spinner-border spinner-border-sm sm-2"></span>}
                                        &nbsp; Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
            }
            { batteryChargerModal && <Modal show={batteryChargerModal} onHide={hideConfirmationModal} className="modal" id="update_switchers">
                    <div className="modal-header">
                        <h5 className="modal-title">Add/Update Battery Charger</h5>  
                        <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                    </div>
                    <div className="modal-body">
                        <Form id='updateSwitchgearsForm' name='updateSwitchgearsForm' onSubmit={handleSubmit(onSwitchgearsSubmit)}>
                            <Form.Control type="hidden" name="tabname" { ...register('tabname') } defaultValue='add_battery_charger' />
                            <Form.Control type="hidden" name="site_id" { ...register('site_id') } defaultValue={site_id} />
                            <Form.Control type="hidden" name="charger_id" { ...register('charger_id') } defaultValue="0" />
                            
                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="float_voltage" className="col-form-label">Float Voltage (V) <strong className="text-danger"> *</strong></Form.Label>
                                <div className="">
                                    <Form.Control type="text" pattern="\d*" maxLength="3" title="Float voltage must be numbers only" name="float_voltage" { ...register('float_voltage',{ required: true}) } className={errors.float_voltage && errors.float_voltage.type === "required" ? 'is-invalid' : '' } />
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="float_current" className="col-form-label">Float Current (A)</Form.Label>
                                <div className="">
                                    <Form.Control type="text" pattern="\d*" maxLength="3" title="Float current must be numbers only" name="float_current" { ...register('float_current') } className={errors.float_current && errors.float_current.type === "required" ? 'is-invalid' : '' } />
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="boost_voltage" className="col-form-label">Boost Voltage (V)</Form.Label>
                                <div className="">
                                    <Form.Control type="text" pattern="\d*" maxLength="3" title="Boost voltage must be numbers only" name="boost_voltage" { ...register('boost_voltage') } className={errors.boost_voltage && errors.boost_voltage.type === "required" ? 'is-invalid' : '' } />
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="boots_current" className="col-form-label">Boost Current (A)</Form.Label>
                                <div className="">
                                    <Form.Control type="text" pattern="\d*" maxLength="3" title="Boost current must be numbers only" name="boots_current" { ...register('boots_current') } className={errors.boots_current && errors.boots_current.type === "required" ? 'is-invalid' : '' } />
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="electrolyte" className="col-form-label">Electrolyte / Terminal</Form.Label>
                                <div className="">
                                    <Form.Control type="text" name="electrolyte" maxLength="24" { ...register('electrolyte') } className={errors.electrolyte && errors.electrolyte.type === "required" ? 'is-invalid' : '' } />
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="remarks" className="col-form-label">Remarks</Form.Label>
                                <div className="">
                                    <Form.Control as="textarea" name="remarks" {...register('remarks')} rows="3" placeholder='Write here...' className={errors.remarks && errors.remarks.type === "required" ? 'is-invalid' : '' }></Form.Control>
                                </div>
                            </div>
 
                            <div className="row mb-1 justify-content-center mt-3">
                                <div className="col-md-12">
                                    <div className="text-end">
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                                            {isSubmitting && <span className="spinner-border spinner-border-sm sm-2"></span>}
                                            &nbsp; Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal>
            }
        </>
    )
}
export default SiteInstallationDetails