import  { useState } from "react";
import { useForm } from 'react-hook-form';
import { Modal, Form, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { format } from 'date-fns';
import { toastNotify, downloadFile} from '../../../components/Helper';
import DatepickerComponent from '../../../components/DatepickerComponent';
import { postFileData }  from '../../../components/Services/Api';

function SiteInstallationDetails(props) {

    const form = useForm();
    const { register, handleSubmit, setValue, formState: { errors } } = form;
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [testsModal, setTestsModal] = useState(false);
    const [testReportModel, setTestReportModel] = useState(false);
    
    const [reportLabel, setReportLabel] = useState('Upload Report ');
    const [testReportStatus, setTestReportStatus] = useState(0);

    const [dateLast, setDateLast] = useState('');
    const [dateNext, setDateNext] = useState('');

    const site_id = props.siteId ? props.siteId : 0;
    const user = props.user ? props.user : null; 
    const testDetails = props.details ? props.details : false;
    const page = props.page ? props.page : 'dashboard';

    // Tests Update 
    async function testsUpdate(data) {
        setValue('test_id', data.id);
        setValue('site_id', site_id);
        setValue('tabname', 'edit_test');
        setDateLast(data.last_servicing ? data.last_servicing : '');
        setDateNext(data.next_servicing ? data.next_servicing : '');

        setTestsModal(true);
    }
    async function testsReportPopup(data) {
        setValue('tabname', 'upload_report');
        setValue('test_id', data.id);
        setValue('site_id', site_id);
        setValue('status', data.reports ? data.reports.status : 0);
        setValue('anomalies', data.reports ? data.reports.anomalies : '');

        setTestReportStatus(data.reports ? data.reports.status : 0);
        setReportLabel(data.name);
        setDateLast(data.last_servicing ? data.last_servicing : '');
        setDateNext(data.next_servicing ? data.next_servicing : '');

        setTestReportModel(true);
    }

    async function onTestsSubmit(formData) {
        let tabname = formData.tabname ? formData.tabname : 'edit_test';
        setIsSubmitting(true);
        saveFormData(formData, tabname);
    }

    async function onTestReportSubmit(data) {
        let formData = new FormData(document.getElementById('updateTestReportForm'));
        let tabname = data.tabname ? data.tabname : '';
        setIsSubmitting(true);
        saveFormData(formData, tabname);
    }

    async function saveFormData(data, tabname = '') {
        try {
            postFileData(`site/manage/${site_id}/${tabname}`, data, 'post', (responce)=> {
                if(responce.success){
                    toastNotify('success', responce.message);
                    setIsSubmitting(false);
                    hideConfirmationModal();
                    props.confirmAction(true);
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        });
                        setIsSubmitting(false);
                    }
                    else {
                        toastNotify('danger', responce.message);
                        setIsSubmitting(false);
                    }
                }
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
            setIsSubmitting(false);
        }
    };

    async function testsReportStatus(status){
        setTestReportStatus(status);
    }

    // Hide the modal
    const hideConfirmationModal = () => {
        setTestsModal(false);
        setTestReportModel(false);
    };

    return (
        <>
            { page === 'dashboard' && testDetails && testDetails.map( (test, ind) => 
                <div className="col" key={ind}>
                    <div className={`${test.overdue ? 'bg-warning-light' : 'bg-white'} h-100 p-3 p-xl-4 shadow-sm border-start border-1 border-primary`}>
                        <div className="d-flex border-bottom pb-3 mb-3">
                            <h6 className="mb-0 align-self-center">{ test.name }</h6>
                            { (test.reports && Number(test.reports.status) === 0) && <svg className="icon text-success fs-5 ms-auto tscale-1p1"><use href="#icon_tick" /></svg> }
                            { (test.reports && Number(test.reports.status) === 1) && <svg className="icon text-danger fs-5 ms-auto tscale-1p2"><use href="#icon_cross" /></svg> }
                            { (!test.reports || Number(test.reports.status) === '') && <svg className="icon text-muted fs-5 ms-auto tscale-1p3"><use href="#icon_help" /></svg> }
                        </div>
                        <div className="row g-2 g-sm-3">
                            <div className="col-6">
                                <div className="d-block bg-light p-3 me-1 rounded-10">
                                    <p className="mb-1 opacity-80">Last Test Date</p>
                                    <h6 className="fw-bold mb-0">{ test.last_servicing && test.last_servicing !== '0000-00-00' ? format(new Date(test.last_servicing), 'dd/MM/yyyy') : 'N/A'  }</h6>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-block bg-light p-3 rounded-10">
                                    <p className="mb-1 opacity-80">Next Test Date</p>
                                    <h6 className="fw-bold mb-0">{ test.next_servicing && test.next_servicing !== '0000-00-00' ? <span className={`${test.overdue ? 'text-danger' : '' }`}>{ format(new Date(test.next_servicing), 'dd/MM/yyyy') } </span> : 'N/A'  }</h6>
                                </div>
                            </div>
                            <div className="col-12 text-center pt-1">
                                { (test.reports && Number(test.reports.status) === 0) && <span className="bg-success bg-opacity-25 d-block p-2 small rounded">No anomalies found as of last test date.</span> }
                                { (test.reports && Number(test.reports.status) === 1) && <span className="bg-danger bg-opacity-25 d-block p-2 small rounded">{test.reports.anomalies}</span> }
                                
                            </div>
                            <div className="col-12 text-center d-flex align-items-center">
                                { (!test.reports || Number(test.reports.status) === '') ? <span className="bg-warning bg-opacity-25 me-auto px-2 py-1 small rounded">Not Applicable</span> : <span className="me-auto"></span>}
                                { (user && user.allowed && user.allowed.includes('tests-update')) && 
                                    <>
                                        <OverlayTrigger placement="top" overlay={<Tooltip> Edit Tests </Tooltip>}>
                                            <button type="button" className="btn btn-secondary px-3 btn-sm" onClick={() => testsUpdate(test)}>Edit</button>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={<Tooltip> Upload Tests Report </Tooltip>}>
                                            <button type="button" className="btn btn-success btn-sm mx-2" onClick={() => testsReportPopup(test)}>Upload</button>
                                        </OverlayTrigger>
                                    </>
                                }
                                { test.reports && test.reports.file && 
                                    <OverlayTrigger placement="top" overlay={<Tooltip> Download Tests Report </Tooltip>}>
                                        <button className="btn btn-sm btn-success" onClick={() => downloadFile(test.reports.file,'report_files')}><svg className="icon tscale-1p4" role="img"><use href="#icon_download" /></svg></button>
                                    </OverlayTrigger> }
                            </div>
                        </div>
                    </div>
                </div>    
            )}

            { page !== 'dashboard' && testDetails && testDetails.map( (test, ind) => 
                <div className="col-md-4 col-sm-6 mb-3" key={ind}>
                    <div className={`${test.overdue ? 'bg-warning-light' : 'bg-white'} h-100 px-3 shadow-sm`} >
                        <div className="d-flex border-bottom pt-2 pb-2 mb-1">
                            <h6 className="mb-0 align-self-center">
                                { (test.reports && Number(test.reports.status) === 0) && <span className="text-success">{ test.name }</span> }
                                { (test.reports && Number(test.reports.status) === 1) && <span className="text-danger">{ test.name }</span> }
                                { (!test.reports || Number(test.reports.status) === '') && <span className="text-muted">{ test.name }</span> }
                            </h6>
                            <div className="ms-auto">
                                { (user && user.allowed && user.allowed.includes('tests-update')) && 
                                    <OverlayTrigger placement="top" overlay={<Tooltip> Edit Tests </Tooltip>}>
                                        <svg className="icon text-success fs-5 me-2" role="button" onClick={() => testsUpdate(test)}>
                                            <use href="#icon_edit"></use>
                                        </svg>
                                    </OverlayTrigger>
                                }
                            </div>
                        </div>
                        <table className="table table-borderless small">
                            <tbody>
                                <tr>
                                <td style={{ width: "180px" }}>Date of Last Scan:</td>
                                    <td>{ test.last_servicing && test.last_servicing !== '0000-00-00' ? format(new Date(test.last_servicing), 'dd/MM/yyyy') : 'N/A'  }</td>
                                </tr>
                                <tr>
                                    <td>Due date of Next Scan:</td>
                                    <td>{ test.next_servicing && test.next_servicing !== '0000-00-00' ? <span className={`${test.overdue ? 'text-danger' : '' }`}>{ format(new Date(test.next_servicing), 'dd/MM/yyyy') } </span> : 'N/A'  }</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div className="col-12 text-center pt-1">
                                            { (test.reports && Number(test.reports.status) === 0) && <span className="bg-success bg-opacity-25 d-block p-2 small rounded">No anomalies found as of last test date.</span> }
                                            { (test.reports && Number(test.reports.status) === 1) && <span className="bg-danger bg-opacity-25 d-block p-2 small rounded">{test.reports.anomalies}</span> }
                                            { (!test.reports || Number(test.reports.status) === '') ? <span className="bg-warning bg-opacity-25 me-auto px-2 py-1 small rounded">Not Applicable</span> : <span className="me-auto"></span>}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        { (user && user.allowed && user.allowed.includes('tests-update')) && 
                                            <OverlayTrigger placement="top" overlay={<Tooltip> Upload Tests Report </Tooltip>}>
                                                <button className="btn btn-primary" onClick={() => testsReportPopup(test)}>Upload report</button>
                                            </OverlayTrigger>
                                        }
                                    </td>
                                    <td>
                                        { test.reports && test.reports.file && 
                                            <OverlayTrigger placement="top" overlay={<Tooltip> Download Tests Report </Tooltip>}>
                                                <button className="btn btn-success" onClick={() => downloadFile(test.reports.file,'report_files')}><svg className="icon" role="img"><use href="#icon_download" /></svg></button>
                                            </OverlayTrigger>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            
            { testsModal && <Modal show={testsModal} onHide={hideConfirmationModal} className="modal" id="update_tests">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit Test</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                    </div>
                    <div className="modal-body">
                        <Form id='updateTestForm' name='updateTestForm' onSubmit={handleSubmit(onTestsSubmit)}>
                        
                            <Form.Control type="hidden" name="tabname" { ...register('tabname') } value='edit_test' />
                            <Form.Control type="hidden" name="site_id" { ...register('site_id') } value={site_id} />
                            <Form.Control type="hidden" name="test_id" { ...register('test_id') } />

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="date" className="col-form-label">Date of Last Scan<strong className="text-danger"> *</strong></Form.Label>
                                <div className="">
                                    <DatepickerComponent type="date" defVal={dateLast} name="last_servicing" form={form} required="true" errors={errors.last_servicing} /> 
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="date" className="col-form-label">Due date for Next Scan</Form.Label>
                                <div className="">
                                    <DatepickerComponent type="date" defVal={dateNext} name="next_servicing" form={form} errors={errors.next_servicing} /> 
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center mt-3">
                                <div className="col-md-12">
                                    <div className="text-end">
                                        <Button variant="primary" type="submit" className="px-4" disabled={isSubmitting}>
                                            {isSubmitting && <span className="spinner-border spinner-border-sm sm-2 me-1"></span>}
                                            &nbsp;&nbsp; Submit &nbsp;&nbsp;
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal>
            }
            { testReportModel && <Modal show={testReportModel} onHide={hideConfirmationModal} className="modal" id="update_test_reports">
                    <div className="modal-header">
                        <h5 className="modal-title">Upload Report - {reportLabel}</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                    </div>
                    <div className="modal-body">
                        <Form id='updateTestReportForm' name='updateTestReportForm' onSubmit={handleSubmit(onTestReportSubmit)}>
                        
                            <Form.Control type="hidden" name="tabname" { ...register('tabname') } value='upload_report' />
                            <Form.Control type="hidden" name="site_id" { ...register('site_id') } value={site_id} />
                            <Form.Control type="hidden" name="test_id" { ...register('test_id') } />

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="date" className="col-form-label">Date of Last Scan<strong className="text-danger"> *</strong></Form.Label>
                                <div className="">
                                    <DatepickerComponent type="date" defVal={dateLast} name="last_servicing" form={form} required="true" errors={errors.last_servicing} /> 
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="date" className="col-form-label">Due date for Next Scan</Form.Label>
                                <div className="">
                                    <DatepickerComponent type="date" defVal={dateNext} name="next_servicing" form={form} errors={errors.next_servicing} /> 
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="status" className="col-form-label">Status<strong className="text-danger"> *</strong></Form.Label>
                                <div className="">
                                    <Form.Select name="status" { ...register('status',{ required: true}) } onClick={(e) => testsReportStatus(e.target.value)} className={errors.status && errors.status.type === "required" ? 'is-invalid form-select' : 'form-select' }>
                                        <option value="0">No anomalies found</option>
                                        <option value="1">Anomalies found</option>
                                    </Form.Select>
                                </div>
                            </div>

                            { testReportStatus && Number(testReportStatus) === 1 ?
                                <div className="row mb-1 justify-content-center">
                                    <Form.Label htmlFor="anomalies" className="col-form-label">Anomalies Details<strong className="text-danger"> *</strong></Form.Label>
                                    <div className="">
                                        <Form.Control type="text" name="anomalies" { ...register('anomalies',{ required: true}) } className={errors.anomalies && errors.anomalies.type === "required" ? 'is-invalid form-control' : 'form-control' } />
                                    </div>
                                </div>
                            : '' }

                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="report" className="col-form-label">Upload Report</Form.Label>
                                <div className="input-group mb-3">
                                    <label className="input-group-text" htmlFor="report">Upload</label>
                                    <Form.Control type="file" className="form-control" id="report" name="report" { ...register('report') } />
                                </div>
                            </div>

                            <div className="row mb-1 justify-content-center mt-3">
                                <div className="col-md-12">
                                    <div className="text-end">
                                        <Button variant="primary" type="submit" className="px-4" disabled={isSubmitting}>
                                            {isSubmitting && <span className="spinner-border spinner-border-sm sm-2 mr-1"></span>}
                                            &nbsp;&nbsp; Submit &nbsp;&nbsp;
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal>
            }
        </>
    )
}
export default SiteInstallationDetails