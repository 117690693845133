import {memo} from 'react'; 
import { Link} from "react-router-dom";
import { format } from 'date-fns';
import  MapContainer from '../../../components/GoogleMap';

function SiteDetails(props) {

    const site = props.site ? props.site : {};
    const user = props.user ? props.user : {};

    var volt_unit = 'V';

    if(site.installation_type && site.installation_type.toUpperCase() === '22KV'){
        volt_unit = 'kV';
    }

    const location = {
        name: site.name,
        address: site.address,
        lat: site.latitude ? parseFloat(site.latitude): '',
        lng: site.longitude ? parseFloat(site.longitude) : ''
    };

    return (
        <>
            <div className="tab-pane fade show active" role="tabpanel">
                <div className="row align-items-center">
                    <div className="col-sm-12">
                        <div className="bg-white px-md-5 py-md-5 px-sm-1 py-sm-1">
                            <div className="table-responsive">
                                <table className="table td-padding-10">
                                    <tbody>
                                        <tr>
                                            <td className="fw-bold">Site Name:</td>
                                            <td className="text-capitalize">{ site && site.name ? site.name : 'N/A' }</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">Customer Name:</td>
                                            <td className="text-capitalize">{ site && site.customer_name ? site.customer_name : 'N/A' }</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">Electrical Installation License No.:</td>
                                            <td>{ site && site.license_no ? site.license_no : 'N/A' } (Expires on: { site && site.license_expiry ? format(new Date(site.license_expiry), 'dd/MM/yyyy') : 'N/A' })
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">Site Address:</td>
                                            <td>{ site && site.address ? site.address : 'N/A' }</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">Postal Code:</td>
                                            <td>{ site && site.postal_code ? site.postal_code : 'N/A' }</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">Intake Voltage:</td>
                                            <td>{ site && site.installation_type ? site.installation_type : 'N/A' }</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">Approved Load:</td>
                                            <td>{ site && site.approved_load ?  site.approved_load : '0' }{volt_unit}</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">No. of PG Intakes:</td>
                                            <td>{ site && site.pg_intakes ? site.pg_intakes : '0' }</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">Owner:</td>
                                            <td className="text-capitalize">{ site && site.client_name ? site.client_name : 'Not Assigned yet.' }</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">License Status:</td>
                                            <td className="text-capitalize">{ site && site.site_license_status ? site.site_license_status : 'Not Found.' }</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">PIC Name:</td>
                                            <td className="text-capitalize">{ site && site.pic_name ? site.pic_name : 'N/A' }</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">PIC Contact Number:</td>
                                            <td className="text-capitalize">{ site && site.pic_contact_number ? site.pic_contact_number : 'N/A' }</td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">SLD:</td>
                                            <td className="text-capitalize">{ site && site.sld ? site.sld : 'N/A' }</td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                                { (location && location.lat && user && user.api_key) && 
                                    <div className="map-container"> 
                                        <MapContainer apiKey={user.api_key} location={location} zoomLevel={16} />
                                    </div> 
                                } 
                                
                            </div>
                            <div className="mt-4 mb-2 p-3">
                                <Link to={`../site/edit/${site.id}`} title={`Edit Site - ${site.name}`}>
                                    <button className="btn btn-sm btn-primary" type="button">
                                        <svg className="icon me-1 tscale-1p4" role="img">
                                            <use href="#icon_edit" />
                                        </svg> Edit Site
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default memo(SiteDetails)