import { createRoot } from 'react-dom/client';
import {useState, useEffect, useContext} from 'react'; 
import { Spinner, Button, Tooltip, OverlayTrigger} from 'react-bootstrap';
import { Context } from '../../components/Context';
import { downloadReport} from '../../components/Helper';

import Datatables, { redrawDataTable } from '../../components/Tabel/Datatables';

function SiteListServerSide(){
    window.document.title = "Sites Report | I2R Portal";

    const [user] = useContext(Context);

    const [dt] = useState({
        dt_url: `site/all`,
        dt_name: 'site_list',
        dt_export : (user && user.allowed && user.allowed.includes('report-site-download')) ? true : false,
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"name", name:'name', title:"Name"},
            { data:"license_no", name:'license_no', title:"License No", class:"text-nowrap text-truncate"},
            { data:"address", name:'address',  title:"Site Address", class:"text-truncate maxw-250"},
            { data:"created_at", name:'created_at',  title:"Added On", class:"text-nowrap" },
            { data:"id", title:"Action", sortable:false, searchable:false, orderable: false, class:"text-nowrap text-center noExport", width:160, "visible" : (user && user.allowed && user.allowed.includes('report-site-download')) ? true : false}
        ],
        dt_column_defs: [
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <div className="text-nowrap">
                            <OverlayTrigger placement="left" overlay={<Tooltip> Download site report </Tooltip>}>
                                <Button className="btn btn-sm btn-success btn-loading" onClick={(e) => downloadReport(e, rowData.id)}>
                                    <Spinner className="spinner-border spinner-border-sm text-right me-2 pe-none" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> 
                                    <svg className="icon tscale-1p3 me-2 pe-none" role="img"><use href="#icon_download" /></svg>
                                    Download
                                </Button>
                            </OverlayTrigger>
                        </div>                             
                    </>)
                },
            },
        ]
    });

    useEffect(() => {
        redrawDataTable(dt)
    }, [dt])

    return(
        <>
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between mb-4">
                    <div className="col">
                        <h4 className="page-heading">Site Reports</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body">
                            { (user && user.allowed && user.allowed.includes('report-site-view')) &&
                                <Datatables dt_name="site_list" dataPageLength="15"/>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default SiteListServerSide