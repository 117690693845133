import { useState, useEffect, memo } from "react";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatepickerComponent = (props) => {

const [fieldName, setFieldName] = useState('');
const [startDate, setStartDate] = useState('');
const [startTime, setStartTime] = useState('');

let currentDataFormate = 'dd/MM/yyyy';
var currentTimeFormate = 'h:mm aa';

useEffect(() => {

  setFieldName(props.name);

  if(props.defDate && props.defDate !== '' && props.defDate !== '0000-00-00'  && props.defDate !== 'Invalid Date'){
    setStartDate(new Date(props.defDate));
  }else if(props.defVal && props.defVal !== '' && props.defVal !== '0000-00-00' && props.defVal !== 'Invalid Date'){
    setStartDate(new Date(props.defVal));
  }

  if(props.defTime && props.defTime !== '' && props.defTime !== 'Invalid Date' && (props.defTime).includes(':')){
    let todayDate = format(new Date(), 'yyyy-MM-dd');
    let start_time = new Date(`${todayDate} ${props.defTime}`);
    setStartTime(start_time);
  }

},[props, fieldName]);

if(fieldName){

  if(props.type && props.type === 'time'){
    if(props.formate && props.formate !== '' && props.formate !== 'Invalid Date'){
      currentTimeFormate = props.formate;
    }
    if( startTime && currentTimeFormate){
      props.form.setValue(fieldName, format(startTime, currentDataFormate));
    }
  }else{
    if(props.formate && props.formate !== '' && props.formate !== 'Invalid Date'){
      currentDataFormate = props.formate;
    }
    if( startDate && currentDataFormate){
      props.form.setValue(fieldName, format(startDate, currentDataFormate));
    }
  }

  if(props.hasOwnProperty(props.type) && props.type === 'time'){

      return (
        <div className="position-relative" htmlFor={fieldName}>
          <DatePicker
            selected={startTime}
            placeholderText={props.placeholder && props.placeholder !== undefined ? props.placeholder : 'XX:XX AM'} 
            name={fieldName} 
            {...props.form.register(fieldName, {required: (props.required && props.required === "true") ? true : false })} 
            onChange={(time) => setStartTime(time)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat={currentDataFormate} 
            className={props.errors && !startTime ? 'is-invalid form-control' : 'form-control' }
            spellCheck="false"
          />
          <svg className="icon position-absolute end-0 me-1 pe-none top-50 translate-middle opacity-75">
            <use href="#icon_clock"></use>
          </svg> 
        </div>
      );

    }else{

      return (
        <div className="position-relative" htmlFor={fieldName}>
        <DatePicker 
          selected={startDate} 
          placeholderText={props.placeholder && props.placeholder !== undefined ? props.placeholder : 'DD/MM/YYYY'}  
          name={fieldName} 
          {...props.form.register(fieldName, {required: (props.required && props.required === "true") ? true : false })}
          onChange={(date) => { props.confirmStartData && props.confirmStartData(date); setStartDate(date)} } 
          minDate={props.minDate ? props.minDate : null} 
          maxDate={props.maxDate ? props.maxDate : null} 
          dateFormat={currentDataFormate}
          className={props.errors && !startDate ? 'is-invalid form-control' : 'form-control' } 
          spellCheck="false" 
        />
        <svg className="icon position-absolute end-0 me-1 pe-none top-50 translate-middle opacity-75">
          <use href="#icon_calendar"></use>
        </svg>
      </div>
      );
    }
  }
}
export default memo(DatepickerComponent)