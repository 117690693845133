/* eslint-disable no-array-constructor */
import { Toast } from "bootstrap";
import { getRequest, postRequest }  from '../components/Services/Api';

export const scrollbarSetToTop = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
    });
}

export const range = (end, start = 0) => {
	// eslint-disable-next-line no-unused-vars
	var len = end - start + 1;
	var a = new Array();
	for (let i = 0; i < end; i++) {
        a[i] = start + i;
    }
	return a;
}

export const blankImage = (ev) => {
    ev.target.src = "images/blank-image.png";
    ev.target.classList.add("p-1");
    ev.target.title="No media found.";
}
export const noimageHandle = (ev) => {
    ev.target.src = "images/no-image.png";
    ev.target.classList.add("p-1");
    ev.target.title="No media found.";
}

export const noAvatarHandle = (ev) => {
    ev.target.src = "images/user.jpg";
    ev.target.title="Profile";
}
export const noMediaHandle = (ev) => {
    ev.target.src = "noimage.png";
    ev.target.title="No media found.";
}

export const scrolldownpage = (e, id) => {
    e.preventDefault();
    if(document.getElementById(id) !== null){
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
        [...document.querySelectorAll('.top-menu a, .bottom-mobile-menu .mobile-link')].forEach((i) => {
            i.classList.remove('active')
        })
        e.target.classList.add('active');
    }else{
        window.location.href = "/"
    }
}


export const toastNotify = (type ='danger', message='') => {
    const idGenerate = Math.floor((Math.random() * 1000000) + 1);
    const htmlToast = `<div id="` + idGenerate + `" class="toast fade bg-white mb-2 border-0" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="4000"><div class="d-flex bg-${type}"><div class="toast-body first-text-uppercase fw-medium1 fs-16px text-white">
        ${message}
    </div><button type="button" class="btn-close ms-auto me-2 mt-2 mmwh-22px" data-bs-dismiss="toast" aria-label="Close" title="Close"></button></div>
    </div>`;
    document.getElementById('toastContainer').insertAdjacentHTML("afterbegin", htmlToast);
    // first toast id initialize then show by bootstrap 
    var getIdToast = document.getElementById(idGenerate);
    var toast = new Toast(getIdToast);
    toast.show();
    getIdToast.addEventListener('hidden.bs.toast', function () {
        setTimeout(() => {
            this.remove();
        }, 600);
    });
}

export const validateForm = (e) => {
    let forms = document.querySelectorAll('.needs-validation')
    let error = 0;
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            if (!form.checkValidity()) {
                error++;
                e.preventDefault();
                e.stopPropagation();
            }
            form.classList.add('was-validated')
        })
    if (error) {
        return false
    } else {
        return true
    }
}

export const blobToBase64 = async (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result)
        };
        reader.readAsDataURL(blob);
    });
}

export const checkFile = (e) => {
    const fileExt = ['jpeg', 'jpg', 'png'];
    const files = e.target.files[0];
    let ext = files.name.split('.').pop();
    if (!fileExt.includes(ext.toLowerCase())) {
        alert('Please upload file in pdf, jpeg, jpg, png format.');
        return ''
    } else {
        return files
    }
}
export const downloadFilewithLoader = (e, fileName, path ='') => {
    if(!fileName || fileName === null){
        toastNotify('danger', 'File not found.');
    }else{
        e.target.classList.add('active');
        const assetURL = process.env.REACT_APP_ASSET_URL;
        let url = `${assetURL}${path}/${fileName}`;
        if(!path || path ===''){
            url = `${assetURL}${fileName}`;
        }
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}`);
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
        document.body.appendChild(link);
        link.click();
        e.target.classList.remove('active');
        link.parentNode.removeChild(link);
    }
}

export const downloadReport = (e, id) =>{
    e.target.classList.add('active');
    getRequest(`reports/download/${id}`, (response)=> {
        if(response.success){
            e.target.classList.remove('active');
            let fileName = response.data.filename || '';
            let path = 'inspection_report';
            downloadFile(fileName, path);
            if(fileName){
                let data = {
                    'filename' :fileName,
                    'type' : path
                };
                postRequest(`reports/delete-download`, data, (response)=> {
                    console.log(response)
                });  
            }
        }else{
            if(response.message === 'validation_error'){
                [response.data].forEach((ele) => {
                    Object.keys(ele).map((v, i) => {
                        return toastNotify('danger', ele[v])
                    });
                })
            }
            else {
                toastNotify('danger', response.message)
            }
            e.target.classList.remove('active');
        }
    });  
}
export const downloadFrsReport = (e, id) =>{
    e.target.classList.add('active');
    getRequest(`fsr/download/${id}`, (response)=> {
        if(response.success){
            e.target.classList.remove('active');
            downloadFile(response.data.filename,'fsr_pdf');
            if(response.data.filename){
                let data = {
                    'filename' :response.data.filename,
                    'type' : 'fsr_pdf'
                };
                postRequest(`reports/delete-download`, data, (response)=> {
                    console.log(response)
                });  
            }
        }else{
            if(response.message === 'validation_error'){
                [response.data].forEach((ele) => {
                    Object.keys(ele).map((v, i) => {
                        return toastNotify('danger', ele[v])
                    });
                })
            }
            else {
                toastNotify('danger', response.message)
            }
            e.target.classList.remove('active');
        }
    });  
}

export const downloadFile = (fileName, path ='') => {
    if(!fileName || fileName === null){
        toastNotify('danger', 'File not found.');
    }else{
        const assetURL = process.env.REACT_APP_ASSET_URL;
        let url = `${assetURL}${path}/${fileName}`;
        if(!path || path ===''){
            url = `${assetURL}${fileName}`;
        }
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}`);
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }
}

export const isNumberKey = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
	if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode !== 190 || charCode !== 110) && (charCode > 105 || charCode < 96)){
        evt.target.value='';
        return false;
    }
    return true;
}

export const isNumberFloatKey = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
}

export const randomString = (len, charSet) => {
    charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var randomString = '';
    for (var i = 0; i < len; i++) {
        var randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
}