import React, { useState, useCallback } from 'react'
import { Modal } from "react-bootstrap";
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from './canvasUtils'
import { styles } from './styles'

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

const ImageCroper = ({ classes, getCropImage, id }) => {
    const [displayCroperModal, setSisplayCroperModal] = useState(false);
    const [imageSrc, setImageSrc] = React.useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    // eslint-disable-next-line no-unused-vars
    const [croppedImage, setCroppedImage] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])



    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation
            );
            console.log('Cropper Image', croppedImage);
            setCroppedImage(croppedImage)
            getCropImage(croppedImage);
            setImageSrc(null);
            hideCroperModal();
        } catch (e) {
            console.error(e)
        }
    }, [imageSrc, croppedAreaPixels, rotation, getCropImage])

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            let imageDataUrl = await readFile(file)

            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }

            setImageSrc(imageDataUrl)
            getCropImage(imageDataUrl);
            setSisplayCroperModal(true);
        }
    }

    const hideCroperModal = () => {
        setSisplayCroperModal(false)
    }

    return (
        <div>
            <input type="file" className='form-control' id={id} onChange={onFileChange} placeholder="eg: jpeg,jpg,png,gif" accept="image/*" />
            {imageSrc && (
                <Modal show={displayCroperModal} onHide={hideCroperModal} className="modal" id="add_new_inspection">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addnewclientsLabel">Image Croper</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={hideCroperModal}></button>
                    </div>
                    <div className="modal-body">
                        <React.Fragment>
                            <div className={classes.cropContainer}>
                                <Cropper
                                    image={imageSrc}
                                    crop={crop}
                                    rotation={rotation}
                                    zoom={zoom}
                                    aspect={4 / 3}
                                    onCropChange={setCrop}
                                    onRotationChange={setRotation}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </div>
                            <div className={classes.controls}>
                                <div className={classes.sliderContainer}>
                                    <Typography
                                        variant="overline"
                                        classes={{ root: classes.sliderLabel }}
                                    >
                                        Zoom
                                    </Typography>
                                    <Slider
                                        value={zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="Zoom"
                                        classes={{ root: classes.slider }}
                                        onChange={(e, zoom) => setZoom(zoom)}
                                    />
                                </div>
                                <div className={classes.sliderContainer}>
                                    <Typography
                                        variant="overline"
                                        classes={{ root: classes.sliderLabel }}
                                    >
                                        Rotation
                                    </Typography>
                                    <Slider
                                        value={rotation}
                                        min={0}
                                        max={360}
                                        step={1}
                                        aria-labelledby="Rotation"
                                        classes={{ root: classes.slider }}
                                        onChange={(e, rotation) => setRotation(rotation)}
                                    />
                                </div>
                            </div>
                            <Button
                                onClick={showCroppedImage}
                                variant="contained"
                                color="primary"
                                classes={{ root: classes.cropButton }}
                                style={{marginRight:'5px'}} >
                                Crop
                            </Button>
                            <Button variant="contained" type="button" onClick={hideCroperModal}>Skip Crop</Button>
                        </React.Fragment>
                    </div>
                </Modal>
            )}
        </div>
    )
}

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const StyledDemo = withStyles(styles)(ImageCroper)

export default StyledDemo

