import {useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink, Link } from "react-router-dom";

function AsideBar() {
    const location = useLocation();
    const [role_id, setRole_id] = useState(0);
    const [user, setUser] = useState('');


    useEffect(() => {
        document.body.classList.remove('menu-active');
        let u = JSON.parse(localStorage.getItem('i2r-user'));
        setUser(u);
        setRole_id(u && u.type ? u.type : 0)
    }, [location.pathname])
    
    return (
        <>
            <aside className="admin-aside">
                <div className="admin-logobox">
                    <Link to="dashboard" className="admin-brandbox" title="I2R Portal">
                        <img src={'images/logo.png'} alt="I2R Portal" width={120} height={56} />
                    </Link>
                </div>
                <div className="admin-navbox" id="navtoggle">
                    <div className="accordion-item">
                        <NavLink to="dashboard" className="main-link">
                            <svg className="icon" role="img">
                                <use href="#icon_dashboard" />
                            </svg>
                            Dashboard
                        </NavLink>
                    </div>

                    { ( user && user.allowed && ( user.allowed.includes('user-view') || user.allowed.includes('staff-view') ) ) && 
                        <div className="accordion-item">
                            <div className="main-link collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#users_more" aria-expanded={ location.pathname.includes('/users/') ? true : false } data-currentpage={ location.pathname.includes('/users/') ? true : false }>
                                <svg className="icon" role="img" aria-label="more">
                                    <use href="#icon_users" />
                                </svg>
                                Users
                            </div>
                            <div id="users_more" className={`accordion-collapse collapse ${location.pathname.includes('/users/') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                { (user && user.allowed && user.allowed.includes('user-view')) &&  <NavLink to="users/list" className="sub-link">All Users</NavLink> }
                                { (user.type && user.type !== 3 && user.allowed && user.allowed.includes('staff-view')) &&  <NavLink to="users/staff" className="sub-link">Staff Members</NavLink> }
                            </div>
                        </div>
                    }
                    { (user && user.allowed) &&
                        <div className="accordion-item">
                            <div className="main-link collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#company_more" aria-expanded={ location.pathname.includes('/site/') ? true : false } data-currentpage={ location.pathname.includes('/site/') ? true : false }>
                                <svg className="icon" role="img" aria-label="more">
                                    <use href="#icon_company" />
                                </svg>
                                Manage Sites
                            </div>
                            {user && user.allowed &&
                                <div id="company_more" className={`accordion-collapse collapse ${location.pathname.includes('/site/') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                    { user.allowed.includes('site-create') && <NavLink to="site/new" className="sub-link">Add New</NavLink> }
                                    { user.allowed.includes('site-view') && <NavLink to="site/index" className="sub-link">All Sites</NavLink> }
                                    { user.allowed.includes('site-upcoming-inspections-view') && <NavLink to="site/upcoming-inspections" className="sub-link">Upcoming Inspections</NavLink> }
                                    { user.allowed.includes('site-view') && <NavLink to="site/archived-sites" className="sub-link">Archived Sites</NavLink> }
                                </div>
                            }
                        </div>
                    }
                    { (user && user.allowed && (user.allowed.includes('report-site-view') || user.allowed.includes('report-tests-view')) ) &&  
                        <div className="accordion-item">
                            <div className="main-link collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#report_more" aria-expanded={ location.pathname.includes('/report/') ? true : false } data-currentpage={ location.pathname.includes('/report/') ? true : false }>
                                <svg className="icon" role="img">
                                    <use href="#icon_star" />
                                </svg>
                                Reports
                            </div>
                            {user && user.allowed &&
                                <div id="report_more" className={`accordion-collapse collapse ${location.pathname.includes('/report/') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                    { user.allowed.includes('report-site-view') && <NavLink to="report/sites" className="sub-link">Sites Report</NavLink> }
                                    { user.allowed.includes('report-tests-view') && <NavLink to="report/tests" className="sub-link">Tests Report</NavLink> }
                                </div>
                            }
                        </div>
                    }

                    { (user && user.allowed && ( user.allowed.includes('fsr-view') || user.allowed.includes('fsr-create') )) && 
                        <div className="accordion-item">
                            <div className="main-link collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#fsr_more" aria-expanded={ location.pathname.includes('/fsr/') ? true : false } data-currentpage={ location.pathname.includes('/fsr/') ? true : false }>
                                <svg className="icon" role="img">
                                    <use href="#icon_bell" />
                                </svg>
                                FSR
                            </div>
                            <div id="fsr_more" className={`accordion-collapse collapse ${location.pathname.includes('/fsr/') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                { user.allowed.includes('fsr-view') && <NavLink to="fsr/list" className="sub-link">All FSR</NavLink> }
                                { user.allowed.includes('fsr-create') && <NavLink to="fsr/new" className="sub-link">Add New</NavLink> }
                            </div>
                        </div>
                    }

                    <div className="accordion-item">
                        <NavLink to="my-profile" className="main-link">
                            <svg className="icon" role="img">
                                <use href="#icon_user" />
                            </svg>
                            My Profile
                        </NavLink>
                    </div>
                    
                    { role_id === 1 && 
                        <div className="accordion-item">
                            <div className="main-link collapsed" data-bs-toggle="collapse" role="button" data-bs-target="#setting_more" aria-expanded={ location.pathname.includes('/settings/') ? true : false } data-currentpage={ location.pathname.includes('/settings/') ? true : false }>
                                <svg className="icon" role="img" aria-label="more">
                                    <use href="#icon_setting" />
                                </svg>
                                Settings
                            </div>
                            <div id="setting_more" className={`accordion-collapse collapse ${location.pathname.includes('/settings/') ? 'show' : ''}`} data-bs-parent="#navtoggle">
                                <NavLink to="settings/portal-settings" className="sub-link">Site Settings</NavLink>
                                <NavLink to="settings/permissions" className="sub-link">Permissions</NavLink>
                            </div>
                        </div>
                    }
                </div>
                <div className="admin-copyrightsbox">&copy; {(new Date().getFullYear())} - Portal - All Rights Reserved.</div>
            </aside>
        </>
    )
}
export default AsideBar;