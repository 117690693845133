// import $ from 'jquery';
import{useState, useEffect, useContext} from 'react'; 
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { toastNotify, downloadFile } from '../../components/Helper';
import { getRequest, fetchData }  from '../../components/Services/Api';
import { Context } from '../../components/Context';
import DatepickerComponent from '../../components/DatepickerComponent';
import SignaturePad from "signature_pad";
var signaturePad = "";

function CreateFsr() {
    window.document.title = "Create FSR | I2R Portal";
    // eslint-disable-next-line no-unused-vars
    const opt = [];

    const assetURL = process.env.REACT_APP_ASSET_URL;
    const navigate = useNavigate();
    
    const [tinyloader, setTinyloader] = useState(false);;
    const form = useForm();
    const { register, handleSubmit, setValue, formState: { errors } } = form;
    const [user] = useContext(Context);
    const [fsrNo, setfsrNo] = useState(0);
    const [endDate, setEndDate] = useState('');
    
    const userId = user && user.id ? user.id : 0;
    const yourName = user && user.name ? user.name : '';
    const yourSignature = user && user.signature ? user.signature : 'signature.png';

    useEffect(() => { 

        setValue('name',yourName);
        setValue('signature1',yourSignature);

        getRequest('fsr/getlast', (response)=> {
            if(response.success){
                const content = response.data;
                setfsrNo(content.new_fsr_no);
                setValue('fsr_no',content.new_fsr_no);
            }
        }); 

        userSignature('draw');

    }, [setValue, userId, yourName, yourSignature])

    const userSignature = (type) =>{
        if(type==='draw'){
            const canvas = document.getElementById('your_signature');
            canvas.width = canvas.parentElement.clientWidth;
            canvas.height = canvas.parentElement.clientHeight;
            signaturePad = new SignaturePad(canvas, {
                backgroundColor: 'rgb(255, 255, 255)',
                penColor: 'rgb(0, 0, 0)'
            });
        }
        if (type==='clear') {
            signaturePad.clear()  
        }
    }

    async function onSiteSubmit(data) {
        setTinyloader(true);
        if(!signaturePad.isEmpty()){
            let signature2 = signaturePad.toDataURL("image/png")
            if(signature2){
                data.signature2 = signature2;
            }
        }
        try {
            fetchData('fsr/new', data, 'post', (responce)=> {
                if(responce.success){
                    toastNotify('success', responce.message);
                    if(data.types === 1 && responce.data.filename){
                        downloadFile(responce.data.filename,'fsr_pdf')
                    }
                    navigate('../fsr/list');
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', responce.message)
                    }
                }
                setTinyloader(false);
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
            setTinyloader(false);
        }
    }

    const getStartData = (mydata) => {
        setEndDate(new Date(mydata));
    }

    return(
        <>
         { ( user.type && user.type !== 0) &&
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between mb-4">
                        <div className="col">
                            <h4 className="page-heading">Create FSR</h4>
                        </div>
                    </div>
                    <form className="p-5 bg-white rounded-25" method='post' id='fsvAddform' name='fsvAddform' >
                        <input type="hidden" name="added_by" { ...register('added_by') } defaultValue={userId} />
                        <div className="row align-items-center justify-content-center">
                            <div className="row mb-3">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="fsrno" className="col-form-label">FSR No.<strong className="text-danger">*</strong></label>
                                    <div className="">
                                        <input type="text" id="fsrno" {...register('fsr_no', { required: true })} className={errors.fsr_no && errors.fsr_no.type === "required" ? 'is-invalid form-control' : 'form-control' } readOnly value={fsrNo} spellCheck="false"/>
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="customer" className="col-form-label">Customer<strong className="text-danger">*</strong></label>
                                    <div className="">
                                        <input type="text" {...register('customer')} id="customer" placeholder="Enter Customer Name" onChange={(e) => { setValue('customer_name',e.target.value) } } className={errors.customer && errors.customer.type === "required" ? 'is-invalid form-control' : 'form-control' } spellCheck="false"/>
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="Tel" className="col-form-label">Tel</label>
                                    <div className="">
                                        <input type="tel" className="form-control" id="Tel" name='tel' { ...register('tel') } maxLength={12} placeholder="Telephone Number" spellCheck="false"/>
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="Address11" className="col-form-label">Address 1<strong className="text-danger">*</strong></label>
                                    <div className="">
                                    <input type="text" id="Address11" name='address_line1' {...register('address_line1', { required: true })} placeholder="Address Line 1" className={errors.address_line1 && errors.address_line1.type === "required" ? 'is-invalid form-control' : 'form-control' } spellCheck="false"/>
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="Address2" className="col-form-label">Address 2</label>
                                    <div className="">
                                        <input type="text" className="form-control" id="Address2" name='address_line2' {...register('address_line2')} placeholder="Address Line 2" spellCheck="false"/>
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="fax" className="col-form-label">Fax</label>
                                    <div className="">
                                        <input type="tel" className="form-control" id="fax" name='fax' {...register('fax')} maxLength={12} placeholder="Fax Number" spellCheck="false"/>
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="yourref" className="col-form-label">Your Ref</label>
                                    <div className="">
                                        <input type="text" className="form-control" id="yourref" name='your_ref' {...register('your_ref')} placeholder="Refrence" spellCheck="false"/>
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="Contact Person" className="col-form-label">Contact Person</label>
                                    <div className="">
                                        <input type="text" className="form-control" id="Contact Person" name='contact_person' {...register('contact_person')} placeholder="Contact Person Name" spellCheck="false"/>
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="yourref11" className="col-form-label">Date<strong className="text-danger">*</strong></label>
                                    <div className="">
                                        <DatepickerComponent type="date" name="date" form={form} required="true" errors={errors.date} />
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-check form-check-inline mt-2">
                                        <input className="form-check-input" name='tests[]' {...register('tests[]')} type="checkbox" id="inlineCheckbox1" value="Testing & Commissioning"/>
                                        <label className="form-check-label" htmlFor="inlineCheckbox1">Testing & Commissioning</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-2">
                                        <input className="form-check-input" name='tests[]' {...register('tests[]')} type="checkbox" id="inlineCheckbox2" value="LEW Attendance"/>
                                        <label className="form-check-label" htmlFor="inlineCheckbox2">LEW Attendance</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-2">
                                        <input className="form-check-input" name='tests[]' {...register('tests[]')} type="checkbox" id="inlineCheckbox3" value="Maintenance & Servicing"/>
                                        <label className="form-check-label" htmlFor="inlineCheckbox3">Maintenance & Servicing</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-2">
                                        <input className="form-check-input" name='tests[]' {...register('tests[]')} type="checkbox" id="inlineCheckbox4" value="Inspection/Checking"/>
                                        <label className="form-check-label" htmlFor="inlineCheckbox4">Inspection/Checking</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-2">
                                        <input className="form-check-input" name='tests[]' {...register('tests[]')} type="checkbox" id="inlineCheckbox5" value="Installation/Replacement works"/>
                                        <label className="form-check-label" htmlFor="inlineCheckbox5">Installation/Replacement works</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-2">
                                        <input className="form-check-input" name='tests[]' {...register('tests[]')} type="checkbox" id="inlineCheckbox6" value="Others"/>
                                        <label className="form-check-label" htmlFor="inlineCheckbox6">Others</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <hr className="bg-dark opacity-10 mb-0 pb-0"></hr>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="location" className="col-form-label">Location<strong className="text-danger">*</strong></label>
                                    <div className="">
                                        <input type="text" {...register('location')} id="location" className={errors.location && errors.location.type === "required" ? 'is-invalid form-control' : 'form-control' } placeholder="Enter Location Name" spellCheck="false"/>
                                    </div> 
                                </div>
                            
                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="date_arrival" className="col-form-label">Date of arrival<strong className="text-danger">*</strong></label>
                                    <div className="">
                                        <DatepickerComponent type="date" confirmStartData={getStartData} name="date_arrival" form={form} required="true" errors={errors.date_arrival} />
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="date_completion" className="col-form-label">Date of Completion<strong className="text-danger">*</strong></label>
                                    <div className="">
                                        <DatepickerComponent type="date" minDate={endDate} name="date_completion" form={form} required="true" errors={errors.date_completion} />
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="time_arrival" className="col-form-label">Time of arrival<strong className="text-danger">*</strong></label>
                                    <div className="">
                                        <DatepickerComponent type="time" name="time_arrival" form={form} required="true" errors={errors.time_arrival} />
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="time_completion" className="col-form-label">Time of Completion<strong className="text-danger">*</strong></label>
                                    <div className="">
                                        <DatepickerComponent type="time" name="time_completion" form={form} required="true" errors={errors.time_completion} />
                                    </div> 
                                </div>

                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="description" className="col-form-label">Work Description/Action taken<strong className="text-danger">*</strong></label>
                                    <div className="">
                                        <textarea id="description" rows={3} name='description' {...register('description', { required: true })} placeholder="Work Description" className={errors.description && errors.description.type === "required" ? 'is-invalid form-control' : 'form-control' }></textarea>
                                    </div> 
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="remark" className="col-form-label">Remark</label>
                                    <div className="">
                                        <textarea className="form-control" id="remark" rows={2} name='remarks' {...register('remarks')} placeholder="Your Remark"></textarea>
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="Signature" className="col-form-label">Your Signature<strong className="text-danger">*</strong></label>
                                    <div className="text-center">
                                    <input type="hidden" id="signature1" { ... register('signature1') } spellCheck="false"/>
                                        { yourSignature ? 
                                            <img src={`${assetURL}signatures/${yourSignature}`} alt="Your Signature" className='border maxh-200' style={{height:'100%', width: '100%'}} />
                                            : ''
                                        }
                                        
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="Signature" className="col-form-label">Customer's Signature</label>
                                    <div className="text-center">
                                        <div className="sign-canvas shadow">
                                            <canvas id="your_signature" width={601} height={200}></canvas>
                                        </div>
                                        <div className="d-flex pt-1">
                                            <em className="small pt-1">Customer's Signature</em>
                                            <button type='button' className="ms-auto btn btn-sm btn-outline-danger px-3 py-0 my-2" title="Clear Sign" onClick={()=>userSignature('clear')}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>                                    
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="name" className="col-form-label">Your Name<strong className="text-danger">*</strong></label>
                                    <div className="">
                                        <input type="text"  id="name" {...register('name', { required: true }) } readOnly={ yourName ? true  : false } className={errors.name && errors.name.type === "required" ? 'is-invalid form-control' : 'form-control' } spellCheck="false"/>
                                    </div> 
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <label htmlFor="cname" className="col-form-label">Customer Name<strong className="text-danger">*</strong></label>
                                    <div className="">
                                        <input type="text" id="cname" {...register('customer_name', { required: true })} placeholder="Customer Name" className={errors.customer_name && errors.customer_name.type === "required" ? 'is-invalid form-control' : 'form-control' } spellCheck="false"/>
                                    </div> 
                                </div>                                  
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center mt-5">
                                    <button type="button" className="btn btn-warning rounded-0 me-2 mb-2" disabled={tinyloader} 
                                        onClick={handleSubmit((data) => onSiteSubmit({
                                                ...data,
                                                types: 0
                                            })
                                        )} >
                                        { tinyloader && <span className="spinner-border spinner-border-sm mr-1"></span> } Save as Draft 
                                    </button>  
                                    <button type="button" className="btn btn-primary rounded-0 mb-2" disabled={tinyloader}
                                        onClick={handleSubmit((data) => onSiteSubmit({
                                                ...data,
                                                types: 1
                                            })
                                        )} >
                                        { tinyloader && <span className="spinner-border spinner-border-sm mr-1"></span> } Submit & Download
                                    </button>  
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
         }
        </>
    )
}
export default CreateFsr