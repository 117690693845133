import  { useState, useEffect, useMemo } from "react";
import { useForm } from 'react-hook-form';
import { Modal, Form, Button, Image, Tooltip, OverlayTrigger } from "react-bootstrap";
import { toastNotify, noimageHandle } from '../../../components/Helper';
import TableContainer from "../../../components/Tabel/TableContainer";
import DeleteConfirmation from "../../../components/DeleteConfirmation";
import DatepickerComponent from '../../../components/DatepickerComponent';
import ImageCroper from '../../../components/ImageCroper';
import ImagePopup from '../../../components/ImagePopup';
import { getRequest,deleteRequest, postFileData }  from '../../../components/Services/Api';
import { Link} from "react-router-dom";

function InspectionFindings(props) {

    window.document.title = "Inspection Findings";

    const assetURL = process.env.REACT_APP_ASSET_URL;

    const form = useForm();
    const { register, handleSubmit, setValue, formState: { errors, isSubmitting } } = form;
    const [ reload, setReload ] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [fsrIds, setFsrIds] = useState({});
    const [modelTitle, setModelTitle] = useState('Add New');
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [id, setId] = useState(null);
    const [inspDate, setInspDate] = useState(null);
    const [checklist, setChecklist] = useState(false);

    const [showImageModel, setShowImageModel] = useState(false);
    const [mediaFile, setMediaFile] = useState(null);

    const site_id = props.siteId ? props.siteId : 0; 
    const user = props.user ? props.user : null; 

    const pageActions = {
        recordsTotal : recordsTotal,
        addnew : (user && user.allowed && user.allowed.includes('site-inspection-findings-manage')) ? true : false, 
        addtype : {
            'type' : 'popup',
        },
        modeid : '#add_new_inspection',
        btntitle : 'Add New',
        displayModal : setDisplayModal
    }
    const [inspectionsData, setInspectionsData] = useState([])

    const inspectionsColumns = useMemo(
        () => [
            {
                Header: "Date",
                accessor: "date",
                disableFilters: true
            },
            {
                Header: "Finding",
                accessor: "finding",
                disableFilters: true
            },
            {
                Header: "Attachment",
                accessor: "file",
                Cell: ({row}) => {
                    if(row.original && row.original.file){
                        return <OverlayTrigger placement="top" overlay={<Tooltip> View & Download </Tooltip>}>
                            <Link to="#" className="me-2" onClick={() => { row.original.file && setShowImageModel(true); setMediaFile(`report_files/${row.original.file}`) }}> 
                                <Image thumbnail src={`${assetURL}report_files/${row.original.file}`} onError={noimageHandle} className="img-60" /> 
                            </Link>
                        </OverlayTrigger>;
                    }else{
                        return '';
                    }
                },
                disableFilters: true
            },
            {
                Header: "status",
                accessor: "status",
                disableFilters: true
            },
            {
                Header: "FSR No.",
                accessor: "fsr_no",
                disableFilters: true
            },
            {
                Header: "Added On",
                accessor: "created_at",
                disableFilters: true
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: ({row}) => {
                    if(row.original.id){
                        return <div className="text-nowrap">
                            { (user && user.allowed && user.allowed.includes('site-inspection-findings-manage')) &&
                                <>
                                    <button className="btn btn-sm btn-primary mx-1" onClick={() => handleUpdate(row.original)}>
                                        <svg className="icon tscale-1p1" role="img"><use href="#icon_edit" /></svg>
                                    </button>
                                    <button className="btn btn-sm btn-danger" onClick={() => handleDelete(row.original.id)}>
                                        <svg className="icon tscale-1p1" role="img"><use href="#icon_trash" /></svg>
                                    </button>
                                </>
                            }
                        </div>;
                    }
                },
                disableSortBy: true,
                disableFilters: true
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reload]
    );

    useEffect(() => {
        setValue('site_id', site_id);
        getFsr();
        getRequest(`site/inspections/${site_id}`,(responce)=> {
            const inspections_data = responce.data.result.original.data;
            setInspectionsData(inspections_data);
            setRecordsTotal(responce.data.result.original.recordsTotal);
            if(responce.data.checklist.chicklist.ht_switchgear && responce.data.checklist.chicklist.ht_switchgear.indexOf("Expiry Date of Fire Extinguisher") > -1){
                setChecklist(true);
            } 
        }); 
        setReload(true);   

    },[reload, setValue, site_id]);

    async function handleUpdate(inspaction){
        setValue('insp_id', inspaction.id);
        setValue('site_id', site_id);
        setValue('tabname', 'inspection');
        setValue('finding', inspaction.finding);
        setValue('status', inspaction.status);
        setValue('fsr_no', inspaction.fsr_no);
        setValue('description', inspaction.description);
        setInspDate(inspaction.insp_date);
        setModelTitle('Update');
        setDisplayModal(true);
    }

    async function onInspFindingSubmit(data) {
        let formData = new FormData(document.getElementById('inspectionFindingsform'));
        let tabname = data.tabname ? data.tabname : '';
        saveFormData(formData, tabname);
    }
    
    async function saveFormData(data, tabname = '') {
        try {
            postFileData(`site/manage/${site_id}/${tabname}`, data, 'post', (responce)=> {
                if(responce.success){
                    toastNotify('success', responce.message);
                    setValue('insp_id', '');
                    hideConfirmationModal();
                    reload === false ? setReload(true) : setReload(false);
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', responce.message)
                    }
                }
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
        }
    };

    async function getFsr(){
        getRequest("fsr/ids", (response)=> {
            if(response.success){
                setFsrIds(response.data);
            }
        });
    }
    async function handleDelete(id){
        setId(id);
        setDisplayConfirmationModal(true);
    }
    // Handle the actual deletion of the item
    const submitDelete = (id) => {
        deleteRequest(`site/action/delete/${id}/inspections`,(responce)=> {
            if(responce.success){
                hideConfirmationModal();
                reload === false ? setReload(true) : setReload(false);
            }else{
                toastNotify('danger', responce.message);
            }
        }); 
    }
    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayModal(false);
        setDisplayConfirmationModal(false);
        setShowImageModel(false);
        
        setValue('finding', '');
        setValue('status', '');
        setValue('fsr_no', '');
        setValue('insp_id', '');
        setValue('inspection_file', '');
        setValue('description', '');
        setInspDate(null);
    };

    const updateCropImage = (img) => {
        setValue('inspection_file', img);
    };

    return (
        <>
            <ImagePopup user={user} fileName={mediaFile} showModal={showImageModel} hideModal={hideConfirmationModal} />
            
            <div className="tab-pane fade show active" role="tabpanel">
                <div className="row align-items-center justify-content-center pt-2">
                    <div className="col-sm-12">
                        <TableContainer columns={inspectionsColumns} data={inspectionsData} pageActions={pageActions} />
                        <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} id={id} message={'Are you sure you want to delete this record?'}  />
                    </div>
                </div>
            </div>

            { displayModal && <Modal show={displayModal} onHide={hideConfirmationModal} className="modal" id="add_new_inspection">
                <div className="modal-header">
                    <h5 className="modal-title" id="addnewclientsLabel">{modelTitle}</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <Form id='inspectionFindingsform' name='inspectionFindingsform' onSubmit={handleSubmit(onInspFindingSubmit)}>
                        <Form.Control type="hidden" name="tabname" { ...register('tabname') } value='inspection' />
                        <Form.Control type="hidden" name="site_id" { ...register('site_id') } value={site_id} />
                        <Form.Control type="hidden" name="insp_id" { ...register('insp_id') } />
                        <div className="row mb-1 justify-content-center">
                            <label htmlFor="date" className="col-form-label">Date<strong className="text-danger"> *</strong></label>
                            <div className="">
                                <DatepickerComponent type="date" name="date" defVal={inspDate} form={form} required="true" errors={errors.date} /> 
                            </div>
                        </div>
                        <div className="row mb-1 justify-content-center">
                            <Form.Label htmlFor="Type">Finding<strong className="text-danger"> *</strong></Form.Label>
                            <div className="">
                                <Form.Control type="text" name="finding" { ...register('finding',{ required: true}) } />
                            </div>
                        </div>
                        <div className="row mb-1 justify-content-center">
                            <Form.Label htmlFor="Modal22" className="col-form-label">Status<strong className="text-danger"> *</strong></Form.Label>
                            <div className="">
                                <Form.Select name="status" { ...register('status',{ required: true}) }>
                                    <option>Pending Rectification</option>
                                    <option>KIV</option>
                                    <option>Completed</option>
                                </Form.Select>
                            </div>
                        </div> 
                        <div className="row mb-1 justify-content-center">
                            <Form.Label htmlFor="fsr_no" className="col-form-label">FSR No</Form.Label>
                            <div className="">
                                <Form.Select id="fsr_no" name="fsr_no" {...register('fsr_no')} >
                                    <option key='' value=''>Please Select</option>
                                    { fsrIds.length>0 && fsrIds.map(fsr_no =>
                                        <option key={ fsr_no } value={ fsr_no }>{ fsr_no }</option>
                                    )};
                                </Form.Select>
                            </div>
                        </div>

                        { !checklist && 
                            <div className="row mb-1 justify-content-center">
                                <Form.Label htmlFor="inspection_file" className="col-form-label">Photo upload <small className="text-danger">(jpeg, jpg, png, gif)</small></Form.Label>
                                <div className="input-group mb-3">
                                    <label className="input-group-text text-success" htmlFor="inspection_file">Upload Photo</label>
                                    <ImageCroper getCropImage={updateCropImage} id="inspection_file"  />
                                    <Form.Control type="hidden" name="inspection_file" { ...register('inspection_file') } />
                                </div>
                            </div>
                        }

                        <div className="row mb-1 justify-content-center">
                            <Form.Label htmlFor="description" className="col-form-label">Description</Form.Label>
                            <div className="">
                                <Form.Control as="textarea" name="description" {...register('description')} rows="3" className={errors.description && errors.description.type === "required" ? 'is-invalid' : '' } placeholder="Inspection description here..."></Form.Control>
                            </div>
                        </div>

                        <div className="row mb-1 justify-content-center mt-3">
                            <div className="col-md-12">
                                <div className="text-end">
                                    <Button variant="primary" type="submit" className="px-4" disabled={isSubmitting}>
                                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        &nbsp; Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
             </Modal>
            }
        </>
    )
}
export default InspectionFindings