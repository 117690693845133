import { createRoot } from 'react-dom/client';
import {useContext, useState, useEffect} from 'react'; 
import { Modal,Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Context } from '../../components/Context';
import { toastNotify, downloadFile, validateForm } from '../../components/Helper';
import Datatables, { reloadDataTable, redrawDataTable } from '../../components/Tabel/Datatables';
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { getRequest, postFileData, deleteRequest }  from '../../components/Services/Api';

function ManageLicenses() {
    window.document.title = "Archived Sites | I2R Portal";
    const [user] = useContext(Context);
    const reload = useState(false);
    const [tinyloader, setTinyloader] = useState(false);
    const {register } = useForm();
    const { register: register2 } = useForm();
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayUploadsModal, setDisplayUploadsModal] = useState(false);
    const [status, setStatus] = useState('');
    const [site, setSite] = useState(null);
    const [id, setId] = useState(0);

    const [dt] = useState({
        dt_url: `site/archived-sites`,
        dt_name: 'site_list',
        dt_export : true,
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"name", name:'name', title:"Site Name"},
            { data:"license_no", name:'license_no', title:"License No", class:"text-nowrap text-truncate"},
            { data:"license_expiry", name:'license_expiry',  title:"Expires on", class:"text-truncate maxw-250"},
            { data:"license_status", name:'licenseStatus.status',  title:"Status", class:"text-nowrap" },
            { data:"id", title:"Action", sortable:false, searchable:false, orderable: false, class:"text-nowrap text-center", width:160, "visible": ( user && user.allowed && user.allowed.includes('site-license-update')) ? true : false }
        ],
        dt_column_defs: [
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        { ( user && user.allowed && user.allowed.includes('site-license-update')) ? 
                            <div className="center d-flex">
                                <div className="me-auto">{rowData.license_no}</div>
                                <OverlayTrigger placement="bottom" overlay={<Tooltip> Upload license details </Tooltip>}>
                                    <button type="button" title='Update' className="btn btn-sm btn-success ms-3" onClick={() => handleUploadLicense(rowData.id)}> 
                                        <svg className="icon tscale-1p2" role="img"><use href="#icon_edit" /></svg>
                                    </button>   
                                </OverlayTrigger>
                               
                            </div> 
                            :
                            <div className="me-auto">{rowData.license_no}</div> 
                        } 
                        <div className='ms-1'>
                            { rowData.license_file && 
                                <OverlayTrigger placement="bottom" overlay={<Tooltip> Download license details </Tooltip>}>
                                    <button type="button" className='btn btn-sm btn-warning' onClick={() => downloadFile(rowData.license_file,'licenses')}>
                                        <svg className="icon tscale-1p5" role="img">
                                            <use href="#icon_download" />
                                        </svg>
                                    </button>
                                </OverlayTrigger>
                            }
                        </div>                         
                    </>)
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <div className="text-nowrap">
                            <OverlayTrigger placement="top" overlay={<Tooltip> {rowData.license_expiry_status === 1 ? `License expired on ${rowData.license_expiry}` : `License valid till ${rowData.license_expiry}`} </Tooltip>}>
                                <span className={rowData.license_expiry_status === 1 ? 'text-danger' : 'text-success'}>
                                    <svg className="icon tscale-1p1 text-info" role="img">
                                        <use href="#icon_clock" />
                                    </svg> 
                                    <span className='mx-2'>{rowData.license_expiry} </span>
                                </span>
                            </OverlayTrigger>
                        </div>                             
                    </>)
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <div className="text-nowrap">
                            { ( user && user.allowed && user.allowed.includes('site-license-update')) &&
                                <OverlayTrigger placement="left" overlay={<Tooltip> Update Status </Tooltip>}>
                                    <button className="btn btn-sm btn-primary mx-1" onClick={() => handleUpdate(rowData.id, rowData.license_status)}>
                                        Update
                                    </button>
                                </OverlayTrigger>
                            }
                            { (user && user.allowed && user.allowed.includes('site-delete')) &&
                                <OverlayTrigger placement="top" overlay={<Tooltip> Delete This Site </Tooltip>}>
                                    <Button variant="danger" className="btn btn-sm btn-danger" onClick={() => handleDelete(rowData.id)}>
                                        <svg className="icon tscale-1p1" role="img"><use href="#icon_trash" /></svg>
                                    </Button>
                                </OverlayTrigger>
                            }
                        </div>                             
                    </>)
                }
            },
        ]
    });

    useEffect(() => {
        redrawDataTable(dt);
    },[dt, reload]);

    async function SelectStatusOption(option){
        setStatus(option);
    }
    async function handleDelete(id){
        setId(id);
        setDisplayConfirmationModal(true);
    }
    // Handle the actual deletion of the item
    const submitDelete = (id) => {
        deleteRequest(`site/delete/${id}`,(responce)=> {
            setDisplayConfirmationModal(false);
            if(responce.success){
                redrawDataTable(dt);
            }else{
                toastNotify('danger', responce.message);
            }
        }); 
    }

    // Update licence status
    const handleUpdate = (id, license_status ='') => {
        setId(id);
        setStatus('');
        getRequest(`site/get/${id}`, (response)=> {
            if(response.success){
                const content = response.data;
                setSite(content);
                setDisplayModal(true);
                setStatus(license_status ? license_status : '');
            }
        });   
    }

    //Uploads licence files
    const handleUploadLicense = (id) => {
        setId(id);
        getRequest(`site/get/${id}`, (response)=> {
            if(response.success){
                const content = response.data;
                setSite(content);
                setDisplayUploadsModal(true);
            }
        });   
    }
    const onLicenceSubmit = (e) => {
        if (validateForm(e)) {
            setTinyloader(true);
            let formData = new FormData(document.getElementById('siteLincenceForm'));
            updateSiteLicence(formData);
        }
    }

    const onStatusSubmit = (e) => {
        if (validateForm(e)) {
            let formData = new FormData(document.getElementById('siteStatusForm'));
            if(formData.get('license_status') === 'Renewed' && !formData.get('license_file')){
                toastNotify('danger', 'Upload license required.');
            }else{
                setTinyloader(true);
                updateSiteLicence(formData);
            } 
        }
    }

    const updateSiteLicence = (data) => {
        try {
            postFileData(`site/update_licence/${id}`, data, 'post', (responce)=> {
                setTinyloader(false);
                if(responce.success){
                    toastNotify('success', responce.message);
                }else{
                    if(responce.message === 'validation_error'){
                        [responce.data].forEach((ele) => {
                            Object.keys(ele).map((v, i) => {
                                return toastNotify('danger', ele[v])
                            });
                        })
                    }
                    else {
                        toastNotify('danger', responce.message)
                    }
                }
                hideConfirmationModal();
                reloadDataTable(dt);
            }); 
        } catch (err) {
            toastNotify('danger', 'Invalid action perform. Please try again later');
            setTinyloader(false);
        }
    }
    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayModal(false);
        setDisplayUploadsModal(false);
        setStatus('');
    };

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between mb-4">
                        <div className="col">
                            <h4 className="page-heading">Archived Sites</h4>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body">
                                <Datatables dt_name="site_list" dataPageLength="15"/>
                                <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} id={id} message={'Are you sure you want to delete this record?'}  />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={displayUploadsModal} onHide={hideConfirmationModal} className="modal" id="uploadsLicence">
                <div className="modal-header">
                    <h5 className="modal-title" id="uploadsLicenceLabel">Upload License - { site ? site.name : 'Box' }</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <form className='needs-validation' id='siteLincenceForm' name='siteLincenceForm' noValidate>
                        <div className="row mb-3 justify-content-center">
                            <label htmlFor="license_file" className="col-md-11 col-form-label">Upload License<strong className="text-danger"> *</strong></label>
                            <div className="col-md-11">
                                <input className="form-control" id='license_file' type="file" name="license_file" {...register('license_file')} required />
                            </div>
                        </div> 
                        <div className="row mb-3 justify-content-center">
                            <div className="col-md-11">
                                <div className="text-end">
                                <button type="button" onClick={(e) => onLicenceSubmit(e) }  className="btn btn-primary btn-lg w-100 rounded-0 mb-3" disabled={tinyloader}>
                                    { tinyloader && <span className="spinner-border spinner-border-sm mr-1"></span> } Submit 
                                </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal show={displayModal} onHide={hideConfirmationModal} className="modal" id="updateLicence">
                <div className="modal-header">
                    <h5 className="modal-title" id="updateLicenceLabel">Upload License - { site ? site.name : 'Box' }</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideConfirmationModal}></button>
                </div>
                <div className="modal-body">
                    <form className='needs-validation' id='siteStatusForm' name='siteStatusForm' noValidate>
                        <div className="row mb-3 justify-content-center">
                            <label htmlFor="license_status" className="col-md-11 col-form-label">Status<strong className="text-danger"> *</strong></label>
                            <div className="col-md-11">
                                <select name="license_status" value={status} {...register2('license_status')} id="license_status" onChange={e => SelectStatusOption(e.target.value)} className={`form-select rounded-0` } required>
                                    <option value="">Please select</option>
                                    <option value="Renewed">Renewed</option>
                                    <option value="Decommissioned">Decommissioned</option>
                                    <option value="No Longer Managed">No Longer Managed</option>
                                </select> 
                            </div>
                        </div>
                        { (status && status === 'Renewed') && 
                            <div className="row mb-3 justify-content-center">
                                <label htmlFor="license" className="col-md-11 col-form-label">Upload License<strong className="text-danger"> *</strong></label>
                                <div className="col-md-11">
                                    <input className="form-control" id='license' type="file" name="license_file" {...register2('license_file')} required />
                                </div>
                            </div> 
                        }  
                        <div className="row mb-3 justify-content-center">
                            <div className="col-md-11">
                                <div className="text-end">
                                <button type="button" onClick={(e) => onStatusSubmit(e) } className="btn btn-primary btn-lg w-100 rounded-0 mb-3" disabled={tinyloader}>
                                    { tinyloader && <span className="spinner-border spinner-border-sm mr-1"></span> } Submit 
                                </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    )
}
export default ManageLicenses