import { useState } from 'react'
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const MapContainer = ({ apiKey, location, zoomLevel }) => {

  const iconMarker = './images/mappin.png';
  const [ selected, setSelected ] = useState({});

  const onSelect = item => {
    setSelected(item);
  }

  const mapStyles = {   
    width: '100%',
    height: '100%'
  };

  const defaultCenter = {
    lat: parseFloat(location.lat), 
    lng: parseFloat(location.lng)
  }

  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={zoomLevel}
        center={defaultCenter}
      >
        { location.address && <Marker 
            title={`${location.name} => ${location.address}`} 
            position={defaultCenter} 
            icon={iconMarker}
            animation={'BOUNCE'}
            onClick={() => onSelect(location)}
          /> 
        }
        {
          selected.address && 
          (
            <InfoWindow
              position={selected}
              clickable={true}
              onCloseClick={() => setSelected({})}
            >
              <div style={{width:'200px'}} className="pin-text fs-6 text-blue p-2">
                <p className="border-bottom"><b className="text-blue" style={{width:'100%'}}> {selected.name}</b></p>
                <span className="text-dark">{selected.address}</span>
                <p className='mt-2'>
                  <a href={`https://maps.google.com/maps/place/?q=${selected.address.replace(/\s+/g, '+')}`} target="_blank" rel="noreferrer">
                    <img src={iconMarker} width={25} alt="icon" /><small className='fs-10'>View on Google Maps</small>
                  </a>
                </p>
              </div>
            </InfoWindow>
          )
        }
      </GoogleMap>
    </LoadScript>
  )
}
export default MapContainer;