import  { Fragment } from 'react';
import { Link } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useGlobalFilter, 
} from 'react-table';

import { Table, Button, Input } from 'reactstrap';
import { Filter, DefaultColumnFilter, GlobalFilter } from './filters';


const TableContainer = ({ columns, data, pageActions, renderRowSubComponent }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 25 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  let table_loader = true;
  
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInClick = (value) => {
    setPageSize(Number(value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  if(Number(pageActions.recordsTotal) === 0 || pageOptions.length === 0){
    table_loader = false;
  }

  async function openModel(){
     pageActions.displayModal(true)
  }

  return (
    <div className="row align-items-center justify-content-center pt-2">
      <div className="col-sm-12">
        <div className="row">
            <div className="col-md-auto mb-2 mb-md-0 pe-md-0">
              <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} />
            </div>
            <div className="col ms-auto d-flex">
                <div className="me-2 dropdown w-auto d-inline-flex">
                    <button type="button" className="btn btn-pageof minw-130 bg-white rounded-0 dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                        <span>{ pageIndex + 1 }-{pageOptions.length} 
                        </span> of
                        <span> {pageOptions.length} </span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-0 px-2 minw-auto text-center">
                        <li>
                            <button type="button" onClick={() => onChangeInClick(25)} title="25" className="dropdown-item text-blue py-2">25</button>
                        </li>
                        <li>
                            <button type="button" onClick={() => onChangeInClick(50)} title="50" className="dropdown-item text-blue py-2">50</button>
                        </li>
                        <li>
                            <button type="button" onClick={() => onChangeInClick(100)} title="100" className="dropdown-item text-blue py-2">100</button>
                        </li>
                        <li>
                            <button type="button" onClick={() => onChangeInClick(500)} title="500" className="dropdown-item text-blue py-2">500</button>
                        </li>
                    </ul>
                </div>
                { pageActions.addnew ? 
                    <div className="ms-auto">
                        { pageActions.addtype.type === 'popup' ?
                          <button type="button" className="btn btn-primary px-3 text-nowrap" onClick={() => openModel()}>
                              <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> {pageActions.btntitle}
                          </button>
                          : '' }
                        { pageActions.addtype.type === 'link' ?
                          <Link to={pageActions.addtype.url} type="button" className="btn btn-primary px-3 text-nowrap" >
                              <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> {pageActions.btntitle}
                          </Link>
                          : ''
                        } 
                        
                    </div>
                  : '' }
            </div>
        </div>
        <div className="table-responsive">
        <Fragment>
          <Table className="table-common" hover {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {generateSortingIndicator(column)}
                      </div>
                      <Filter column={column} />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className='text-secondary'> 
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded && (
                        <tr>
                          <td colSpan={visibleColumns.length}>
                            {renderRowSubComponent(row)}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
                <tr className={ pageOptions.length >0 ? 'd-none' : '' }>
                  <td colSpan={columns.length} align="center">
                    {
                      table_loader ? <div className="spinner-border text-primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div> : <span className='text-muted'>No data available in table</span>
                    }
                  </td>
                </tr>
            </tbody>
          </Table>
        </Fragment>
        </div>

        <div className='row'>
            <div className="col-lg-2 col-xl-4 justify-content-start"> 
                 <div className="g-2"> Total{': '}<strong> {data.length} </strong> {' record(s)'}</div> 
            </div>
            <div className='col-lg-10 col-xl-8 justify-content-end'>
              <div className='row justify-content-center justify-content-lg-end g-2'>
                <div className='col-auto'>
                  <Button className='btn btn-dark' onClick={() => gotoPage(0)} disabled={!canPreviousPage} style={{marginRight:'1px'}}> {'<<'} </Button>
                  <Button type='button' className='btn btn-primary minw-100' onClick={previousPage} disabled={!canPreviousPage}>Previous</Button>
                </div>
                <div className='col-auto d-flex align-items-center'>
                  <span>Page</span> 
                  <Input type="number" className='form-control maxw-70 mx-2' min="1" max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput}/>
                  <span>of</span>
                  <span className='mx-2'>{pageOptions.length}</span>
                    <Input
                      className='form-select rounded-0 maxw-120'
                      type='select'
                      value={pageSize}
                      onChange={onChangeInSelect} >
                      {[25, 50, 100, 200, 500].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize} rows
                        </option>
                      ))}
                    </Input>
                </div>
                <div className='col-auto'>
                  <Button type='button' className='btn btn-primary minw-100' onClick={nextPage} disabled={!canNextPage}>Next</Button>
                  <Button className='btn btn-dark' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} style={{marginLeft:'1px'}}> {'>>'} </Button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}
export default TableContainer