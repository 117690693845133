
import { Outlet } from "react-router-dom";
import HeaderBar from "../components/HeaderBar";
import AsideBar from "../components/AsideBar";

function LayoutOfAsideAndHeader() {
    return (
        <>
            <HeaderBar />
            <AsideBar />
            <Outlet />
        </>
    )
}
export { LayoutOfAsideAndHeader}