import {useState, useEffect, useContext} from 'react'; 
import { Context } from '../../components/Context';
import Datatables, { redrawDataTable } from '../../components/Tabel/Datatables';

function UpcomingInspections() {
    window.document.title = "Upcoming Inspections | I2R Portal";
    const [user] = useContext(Context);

    const [dt] = useState({
        dt_url: `site/upcoming-inspections`,
        dt_name: 'site_list',
        dt_export : true,
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"name", name:'name', title:"Name", class:"text-truncate"},
            { data:"license_no", name:'license_no', title:"License No", class:"text-nowrap"},
            { data:"last_inspection", name:'last_inspection',  title:"Last Inspection", class:"text-nowrap"},
            { data:"next_inspection", name:'next_inspection',  title:"Inspection Due Date", class:"text-nowrap" },
        ]
    });

    useEffect(() => {
        redrawDataTable(dt) 
    },[dt]);

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between mb-4">
                        <div className="col">
                            <h4 className="page-heading">Upcoming Inspections</h4>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body">
                            {( user && user.allowed && user.allowed.includes('site-upcoming-inspections-view')) && 
                                <Datatables dt_name="site_list" dataPageLength="15"/>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default UpcomingInspections